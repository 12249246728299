import React, { useState } from 'react';
import Donut from './Donut';
import { useNavigate } from "react-router-dom";
import bin from '../assets/bin.png';
import { saveToLocal } from "../utils/utils";
import { useOutcomeData, useSelectedOutcomeData } from '../Context/OutcomeDataProvider';
// SET SELECTED OUTCOME
function Outcome({ outcome, index, setDeleteOutcomeID, setActiveGGSet }) {
    const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();

    const navigate = useNavigate();
   
    function loadGridgoals() { 
        setActiveGGSet(index);
        setSelectedOutcome(outcome);
        saveToLocal("selected-outcome", outcome);
        
        let path = `/gridgoals`;
        navigate(path);
    }
   
    return (
        <div className="outcome-container" onClick={(e) => { loadGridgoals() }}>
            <div className="output-left">
                <div className="dropdown-outcome">
                    <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.2876 0.5H3.05491C0.339458 0.5 -0.978857 3.81993 0.99688 5.68277L10.9707 15.0867C12.1146 16.1652 13.897 16.1777 15.0559 15.1154L25.3148 5.71146C27.3316 3.86266 26.0236 0.5 23.2876 0.5Z" fill="#8E8E8E" />
                    </svg>
                </div>
                {/* <img className="outcome-image" src={require(`../assets/goalTypes/${outcome.icon}.png`).default} alt="add goal" /> */}
                <div className="outcome-title">{outcome.title}</div>

            </div>

            <div className="output-right">
                {/* <div className="outcome-component">
                    <div className="outcome-name">Finished Grid Goals</div>
                    <div className="outcome-content">{outcome.completed_grid_goals}</div>
                </div> */}

                <div className="outcome-component">
                    <div className="outcome-name">Active Grid Goals</div>
                   
                    <div className="outcome-content">{outcome.active_gridgoal_ids? outcome.active_gridgoal_ids.split(" ").filter(id => id !== "").length:0}</div>
                </div>

                <div className="grid-goal-delete" onClick={(e) => {e.stopPropagation(); setDeleteOutcomeID(outcome.outcome_id)}}>
                    <img src={bin} alt="Delete Outcome" />
                </div>
            </div>


            {/* <div className="outcome-component">{outcome.total_grid_goals}</div> */}
            {/* <div className="outcome-component">{outcome.completed_grid_goals}</div> */}

            {/* <div className="outcome-start">{outcome.start_date}</div>
            <div className="outcome-component">{outcome.end_date}</div> */}
            {/* <Donut /> */}
        </div>


    )
}

export default Outcome
