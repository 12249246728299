import React from 'react'
import { useState } from 'react';
import { numberWithCommas } from '../utils/utils';
import { recordAction } from '../apiRequests/auth';

function formattedNumberToString(n) {
    return Number(n.replace(/,/g, ''))
}

function isNumeric(str) {
    if (typeof str !== "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


function Form({ submitForm }) {
    const [fieldValue, setFieldValue] = useState('ADD AMOUNT');
    const handleSubmit = (e) => {
        e.preventDefault();
        e.target[1].value = ""
        if (isNumeric(fieldValue)) {
            recordAction("Add to Grid Goal");
            submitForm(formattedNumberToString(fieldValue));
        }
    }

    const reSetNumber = (e) => {
        let text = ""
        text = e.target.value.replace(/,/g, '')

        if (isNumeric(text)) {
            e.target.value = text === "-" ? text : numberWithCommas(formattedNumberToString(e.target.value))
            setFieldValue(text)
        }

    }

    return (
        <>
            <div className="add-amount-container">
                <form className={"form-inline"} onSubmit={e => { handleSubmit(e) }} autoComplete="off">
                    <div className={"add-amount-field"}>

                        <input
                            className="submit-button plus-icon"
                            type='submit'
                            value=' ' />
                        <input
                            className="text-input"
                            name="number"

                            // pattern="[+-]?\d+(?:[.,]\d+)?"
                            // pattern="^\d{1,3}(,\d{3})*(\.\d+)?$"
                            // type="number"
                            placeholder='ADD AMOUNT'
                            autoFocus
                            required
                            onChange={e => reSetNumber(e)}
                        />
                    </div>

                </form>

            </div>


        </>
    )

}

export default Form;