import axios from 'axios';
import { getFromLocal } from "../utils/utils";
import ALL_GLOBAL_VARIABLES from "../global";
import React, { useState, useEffect } from "react";


export function recordAction(action) {
  const user_id = getFromLocal("user_id", -1);
  if (ALL_GLOBAL_VARIABLES.useRecordAPI) {
    axios.post(`${ALL_GLOBAL_VARIABLES.host}/record-action`,
      {
        "user_id": user_id,
        "action": action,
      }
    ).then((response) => {
    })
  }
}

export function loadUserDataOutcomes(user_id, username, setIncomingOutcomesDBData, setOutcomesDBData) {
  axios.post(`${ALL_GLOBAL_VARIABLES.host}/all-outcomes-by-id`,
    {
      "username": username,
      "user_id": user_id
    }
  ).then((response) => {

    setIncomingOutcomesDBData(true);
    setOutcomesDBData(response.data)
    return response.data
  })
}

export const getActiveOutcomes = (user_id, username, setOutcomeDatas, setSelectedOutcome, saveToLocal, setUseGoalDatas) => {
  const allGoals = [];
  axios.post(`${ALL_GLOBAL_VARIABLES.host}/all-outcomes-by-id`,
    {
      "username": username,
      "user_id": user_id
    }
  ).then((response) => {
    setOutcomeDatas(response.data)
    setSelectedOutcome(response.data[0] || {})
    saveToLocal("outcome-activity-data", response.data);

    if (response.data[0]) {
      const allGoals = {}
      Promise.all(response.data.map((data) => {
        const outId = data.outcome_id;
        return new Promise(function(resolve, reject) {
          axios.post(`${ALL_GLOBAL_VARIABLES.host}/all-goals-by-outcome-id`,
            {
              "username": username,
              "user_id": user_id,
              "gg_set": data.active_gridgoal_ids
            }
          ).then((response) => {
            // allGoals.outId = response.data
            const outObj = [outId,response.data];
            return resolve(outObj)
          })
        })
      })).then((response) => {
        const goalObj = {}
        response.forEach(ar => {
          goalObj[ar[0]] = ar[1]
        });
        setUseGoalDatas(goalObj);
        saveToLocal("grid-goal-activity-data",goalObj);
      })
    }
  })
}




