import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GoalDataProvider } from './Context/GoalDataProvider'
import { LoginProvider } from './Context/LoginProvider'
import { OutcomeDataProvider } from './Context/OutcomeDataProvider';
ReactDOM.render(
  <React.StrictMode>
    <OutcomeDataProvider>
      <GoalDataProvider>
        <LoginProvider>
          <App />
        </LoginProvider>
      </GoalDataProvider>
    </OutcomeDataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);