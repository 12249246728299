import React from 'react'
import { Link } from "react-router-dom";

function NavLinks({ disableClick, comingSoonAnalytics,comingSoonAccountability, outcomesExists}) {
    return (
        <nav className="nav-links">
            <Link to="/outcomes" >Outcomes</Link>
            {!outcomesExists ?
                <div className="gridgoal-link" onClick={disableClick}>
                    Gridgoals
                    <div className="gridgoal-link-tooltip">You need to create an Outcome before you can go to "Gridgoals"</div>
                </div> :
                <Link to="/gridgoals" >Gridgoals</Link>
            }

            {/* <a href="https://paper.gridgoal.com">Paper</a> */}

            {/* <div className="gridgoal-link" onClick={comingSoonAnalytics}>
                Analytics
                <div className="gridgoal-link-tooltip-analytics">Coming Soon</div>
            </div>
            <div className="gridgoal-link" onClick={comingSoonAccountability}>
                Accountability
                <div className="gridgoal-link-tooltip-accountability">Coming Soon</div>
            </div> */}
        </nav>
    )
}

export default NavLinks
