import React from 'react';

function ExampleElement({text, action, color}) {
    return (
        <div className={`example-element ${color}`} onClick={()=>{action(text.value)}}>
            {text.display}
        </div>
    )
}

export default ExampleElement
