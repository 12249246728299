// Burger.js


// import { StyledBurger } from './Burger.styled';

import React, { useEffect, useState } from 'react';
import { useLogin } from '../Context/LoginProvider'
import NavLinks from './NavLinks';
import '../styles/burger.css';


function Burger({ disableClick, comingSoonAnalytics, comingSoonAccountability, outcomesExists, modalStates }) {
    const [open, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useLogin();
    const {forgotPassword, setForgotPassword,
        mainLogin, setMainLogin,
        registerUser, setRegisterUser,
        setOpenLogin, openLogin} = modalStates;

    const handleChange = (e) => {
        let burger = document.querySelector('.burger-container');
        let navContainer = document.querySelector('.mobile-nav-container')
        burger.classList.toggle('burger-change');

        navContainer.classList.toggle('open-mobile-nav');
        navContainer.classList.toggle('close-mobile-nav');

        navContainer.style.display = navContainer.classList.contains('open-mobile-nav') ? "flex" : 'none';

        setOpen(!open)
    }

    return (

        <>
            <div className="burger-container" onClick={handleChange}>
                <div className="burger-bar1"></div>
                <div className="burger-bar2"></div>
                <div className="burger-bar3"></div>
            </div>
            
            <div className={`mobile-nav-container close-mobile-nav`}>

                <NavLinks disableClick={disableClick} comingSoonAnalytics={comingSoonAnalytics} comingSoonAccountability={comingSoonAccountability} outcomesExists={outcomesExists} open={open} />

                <div className="mobile-error-message">
                    {!outcomesExists ?
                        `You need to create an Outcome before you can go to "Gridgoals"`
                        : ""
                    }

                </div>

                {/* {loggedIn ?
                   <></> :
                    <div className="nav-actions-mobile">
                        <div className="sign-in nav-action nav-action-mobile" onClick={() => { setMainLogin(true); setRegisterUser(false); setOpenLogin(!openLogin); }}>Sign in</div>
                        <div className="sign-up nav-action nav-action-mobile" onClick={() => { setRegisterUser(true); setMainLogin(false); setOpenLogin(!openLogin); }}>Create Account</div>
                    </div>
                } */}



            </div>


        </>
    )
}

export default Burger