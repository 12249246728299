import React, { useState, useEffect } from 'react';
import "../styles/login.css";
import axios from 'axios';
import { saveToLocal, getFromLocal } from '../utils/utils';

import { useLogin } from '../Context/LoginProvider'
import { recordAction, getActiveOutcomes } from '../apiRequests/auth';
import ALL_GLOBAL_VARIABLES from "../global";

import close_icon from '../assets/closeicon--black.svg'
import { useOutcomeData, useSelectedOutcomeData } from '../Context/OutcomeDataProvider';
import { useGoalData, useSelectedGoalData } from '../Context/GoalDataProvider'

function Login({ submitForm, createNewAccount, firstTime, setFirstTime, setOpenLogin, modalStates }) {
    const {forgotPassword, setForgotPassword,
        mainLogin, setMainLogin,
        registerUser, setRegisterUser} = modalStates

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
  
    const [loginError, setLoginError] = useState(false)

    // CONTEXT PROVIDORS
    const [goalDatas, setGoalDatas] = useGoalData();
    const [loggedIn, setLoggedIn] = useLogin();
    const [selectedGoal, setSelectedGoal] = useSelectedGoalData();
    const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();
    const [outcomeDatas, setOutcomeDatas] = useOutcomeData();


    const closeModal = (e) => {
        setOpenLogin(false);
    };



    const handlePasscodeReset = (e) => {
        setMainLogin(false);
        setRegisterUser(false);
        e.preventDefault();
        e.target.reset();
        recordAction("Forgot Password");
    }

    const handleCreateAccount = (e) => {
        setMainLogin(false);
        setRegisterUser(false);

        e.preventDefault();

        axios.post(`${ALL_GLOBAL_VARIABLES.host}/create-account`,
            {
                "username": username,
                "password": password
            }
        ).then((response) => {
            if (response.data.added === true) {
                // Get user idp

                saveToLocal("user_id", response.data.id)
                saveToLocal("username", username);

                createNewAccount(response.data)
                setLoggedIn(true);

                recordAction("Create Account");
                setOpenLogin(false);

            }
            else {
                // Handle no login
            }
        })
        e.target.reset();
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        // setOpenLogin(!openLogin);
        axios.post(`${ALL_GLOBAL_VARIABLES.host}/login`,
            {
                "username": username,
                "password": password
            }
        ).then((response) => {
            console.log(`response`, response)
            if (response.data !== -1) {
                saveToLocal("user_id", response.data);
                saveToLocal("username", username);

                getActiveOutcomes(response.data, username, setOutcomeDatas, setSelectedOutcome, saveToLocal, setGoalDatas);

                setUsername("");
                setPassword("");
                setLoggedIn(true);
                setFirstTime(false);
                setOpenLogin(false);
                recordAction("Login");

            }
            else {
                setLoginError(true)
                // Handle no login
            }

        })

        e.target.reset();

    }

    const handleLogout = (e) => {
        recordAction("Logout");
        saveToLocal("user_id", -1)

        saveToLocal("grid-goal-activity-data", [])
        saveToLocal("outcome-activity-data", {});

        setRegisterUser(false);
        setForgotPassword(false);
        setMainLogin(true);
        setLoggedIn(false);
        setGoalDatas([]);
        setSelectedGoal({})//THis is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
        setSelectedOutcome({})
        setOutcomeDatas([])

        localStorage.clear();
        window.location.replace(`${window.location.protocol}//${window.location.host}/outcomes`); 
        // Clear Local Storage
        // Clear all states

    }
    const reSetUsername = (e) => {
        setUsername(e.target.value)
    }

    const reSetPassword = (e) => {
        setPassword(e.target.value)
    }


    useEffect(() => {

        if (loggedIn) {
            setRegisterUser(false);
            setForgotPassword(false);
            setMainLogin(false);

        }
    }, [loggedIn])

    // Prevent the parent canceller from interfering with the child
    // useEffect(() => {
    //     document.querySelector('.login-modal').addEventListener('click', function (e) {
    //         e.stopPropagation();
    //     }, true);
    //     //   ref={loginRef}
    // }, [])



    {/*  // <div className="login-modal-container" onClick={closeModal}>
            <div  className="login-modal-container" > */}
    return (
        <section className="login-modal">
            <div className="modal__close-login" onClick={()=>setOpenLogin(false)}>
                <img
                    src={close_icon}
                    className="modal__close--icon"
                    alt="Close This Modal"
                />
            </div>
            {mainLogin ?
                <>
                    <div className="login-welcome">Login</div>
                    <div className="login-welcome-subtext">Sign into your account</div>

                    <form className={"login-form"} onSubmit={e => { handleSubmit(e); }} autoComplete="on">
                        <input
                            className="login-input"
                            name="username"
                            placeholder='Email'
                            required
                            value={username}
                            onChange={(e) => reSetUsername(e)}
                            autoComplete="on"
                        />

                        <input
                            className="login-input"
                            name="password"
                            type="password"
                            placeholder='Password'
                            autoComplete="on"
                            required
                            value={password}
                            onChange={(e) => reSetPassword(e)}
                        />

                        {loginError ?
                            <div className="error-message-container">
                                <div className="error-top">Oh Snap!</div>
                                <div className="error-bottom">That was an incorrect email and password</div>
                            </div>
                            : <></>}
                        <input
                            className="login-submit"
                            type='submit'
                            value='Login'
                        />
                    </form>

                    {/* <div className="forgot-password" onClick={e => {
                        setForgotPassword(true);
                        setMainLogin(false);
                        setRegisterUser(false);
                    }}>
                        I forgot my password
                            </div> */}

                    <button className="register-new-account" onClick={e => {
                        setRegisterUser(true);
                        setForgotPassword(false);
                        setMainLogin(false);
                    }}>
                        Register New Account
                            </button>
                </> : <></>}

            {/* {forgotPassword ?
                <>
                    <div className="login-welcome">Password Reset</div>
                    <div className="login-welcome-subtext">It's ok, this happens to everyone</div>

                    <form className={"login-form"} onSubmit={e => { handlePasscodeReset(e) }} autoComplete="off">
                        <input
                            className="login-input"
                            name="Email"
                            placeholder='Email'
                            required
                            value={username}
                            onChange={(e) => reSetUsername(e)}
                            autoComplete="off"
                        />

                        <input
                            className="login-submit"
                            type='submit'
                            value='Reset Password'
                        />
                    </form>
                </> : <></>} */}

            {registerUser ?
                <>
                    <div className="login-welcome">Welcome</div>
                    <div className="login-welcome-subtext">Create a New Account</div>

                    <form className={"login-form"} onSubmit={e => { handleCreateAccount(e) }} autoComplete="off">
                        <input
                            className="login-input"
                            name="Email"
                            placeholder='Email'
                            required
                            value={username}
                            onChange={(e) => reSetUsername(e)}
                            autoComplete="off"
                        />

                        <input
                            className="login-input"
                            name="password"
                            type="password"
                            placeholder='Password'
                            required
                            value={password}
                            onChange={(e) => reSetPassword(e)}
                        />
                        <input
                            className="login-submit"
                            type='submit'
                            value='Create Account'
                        />
                    </form>
                </> : <></>}

            {loggedIn ?
                <>
                    <div className="login-welcome">Logout</div>
                    <div className="login-name">{getFromLocal('username', "")}</div>
                    <button className="register-new-account" onClick={handleLogout}>LogOut</button>
                </> : <></>}

            {!loginError ?

                (firstTime && registerUser) || registerUser ?
                    <div className="make-an-account">
                        Please make an account to save your progress for later
                    </div>
                    :
                    <div className="help-email-container">
                        <div className="help-message">
                            Thank you for helping. If you see something wrong or notice a bug, Please send me an email at: <br /> <b className="help-email">GridGoalApp@gmail.com</b>
                        </div>
                    </div>

                : <></>}

        </section>

    )
}

export default Login
