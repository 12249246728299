import React, { useState, useEffect } from "react";


import { mapNumber, getNumColumns } from '../utils/utils';
import GridgoalCell from './GridgoalCell';
import Form from "./Form";
import { saveToLocal, numberWithCommas, getFromLocal, isEmpty, expandProgress } from '../utils/utils';
import axios from 'axios';
import { useGoalData, useSelectedGoalData } from '../Context/GoalDataProvider'
import ALL_GLOBAL_VARIABLES from "../global";
import { useSelectedOutcomeData } from '../Context/OutcomeDataProvider';

function getCellSize(numCols, num_cells, gridHeight, gridWidth) {
    let cellSize = 100;
    let minSize = 30;
    let numRows = Math.ceil(num_cells / numCols)
    for (let i = cellSize; i >= minSize; i--) {

        if (numCols * cellSize + (numCols + 1) * 5 < gridWidth && numRows * cellSize + (numRows + 1) * 5 < gridHeight) { //5 pixel buffer
            return cellSize
        }
        cellSize--;
    }
    return minSize // default grid cell size

}
function handleResize(goal) {
    if (!goal || isEmpty(goal) || goal.id === -1) {
        return
    }
    let gridGoalMainContainer = document.querySelector('.grid-goal-container');
    let gridContainer = document.querySelector('.grid-goal-body-content');
    if (!gridContainer || !goal) {
        return
    }
    let titleComponent = document.querySelector('.grid-goal-header-container');
    let previewText = document.querySelector('.preview-text-per-basis');
    let outcomeTitle = document.querySelector('.current-outcome-title');

    let totalHeaderHeight = titleComponent.offsetHeight + previewText.offsetHeight + outcomeTitle.offsetHeight;



    // let gridDims = gridContainer.getBoundingClientRect();
    let gridDims = gridContainer.getBoundingClientRect();
    let gridWidth = gridDims.width;
    let gridHeight = gridGoalMainContainer.offsetHeight - totalHeaderHeight;


    let availableHeight = window.innerHeight - totalHeaderHeight - 130; //The 130 is the height of the grid goal information bar

    // availableHeight = gridContainer.offsetHeight;
    let numCols = getNumColumns(goal.num_cells, gridWidth, availableHeight)

    let cellSize = getCellSize(numCols, goal.num_cells, availableHeight, gridWidth); //Math.min(100,Math.max(25, (gridWidth - 5 * (numCols - 1)) / numCols));
    let root = document.documentElement;
    let fontSize = mapNumber(cellSize, 25, 100, 5, 25);


    let gridGoalName = mapNumber()

    root.style.setProperty('--cell-font-size', fontSize + "px");
    root.style.setProperty('--grid-goal-name', gridGoalName + "px");
    root.style.setProperty('--cell-width', cellSize + "px");
}

function saveUpdatedGoals(data) {
    const user_id = getFromLocal("user_id", -1)
    if (user_id !== -1) {
        axios.post(`${ALL_GLOBAL_VARIABLES.host}/update_goals_by_outcome_id`,
            {
                "user_id": user_id,
                "grid_data": data,
            }
        ).then((response) => {

        })
    }
}



function Gridgoal({ setRefreshCharts, goalData }) {
    const [formData, setFormData] = useState([]);
    const [perDayText, setPerDayText] = useState('');
    const [goalDatas, setGoalDatas] = useGoalData();

    const [selectedGoal, setSelectedGoal] = useSelectedGoalData();
    const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();
    const [expandedProgress, setExpandedProgress] = useState('');
    const [progressArray, setProgressArray] = useState('');


    const submitForm = (log) => {
        let tempLog = log;
        if (log === formData) {
            tempLog++;
        }

        let goalIndex = -1;
        for (let i = 0; i < goalDatas[selectedOutcome.outcome_id].length; i++) {
            if (goalData.id === goalDatas[selectedOutcome.outcome_id][i].id) {
                goalIndex = i;
            }
        }

        setFormData(tempLog);

        let now = new Date();
        let endDate = new Date(goalData.due_date);
        let daysLeft = Math.floor((endDate.getTime() - now.getTime()) / 1000 / 86400);
        let totalDays = goalData.total_time;
        // let dayNum = daysLeft
        let dayNum = Math.floor(totalDays - daysLeft - 1);


        if (Number(log) < 0 && goalData.progress[dayNum]) {
            log = Math.max(-1 * goalData.progress[dayNum], log)
        }

        isEmpty(goalData.progress) || !goalData.progress[dayNum] ? goalData.progress[dayNum] = Number(log) : goalData.progress[dayNum] += Number(log);

        goalData.total_completed += Number(log);
        goalData.total_completed = Math.max(0, Math.min(goalData.total_completed, goalData.value))

        // Why am I using this?
        // localStorage.setItem(`gridgoal-activity-${selectedGoal.id}`, selectedGoal.total_completed);
        // setGoal(selectedGoal)

        let total_completed = 0;
        for (var key of Object.keys(goalData.progress)) {
            total_completed += goalData.progress[key]
        }

        // let total_completed = Object.keys(goalData.progress).map(elem => goalData.progress[elem]).reduce((partial_sum, a) => partial_sum + a, 0);
        total_completed = Math.max(0, Math.min(total_completed, goalData.value));

        goalData.total_completed = total_completed;

        goalDatas[selectedOutcome.outcome_id][goalIndex]["total_completed"] = Math.max(0, Math.min(total_completed, goalData.value));
        goalDatas[selectedOutcome.outcome_id][goalIndex]["progress"] = goalData.progress

        setGoalDatas({...goalDatas});

        saveToLocal("grid-goal-activity-data", goalDatas);


        saveUpdatedGoals(goalDatas[selectedOutcome.outcome_id]);

        let expanded = expandProgress(goalData.progress, goalData.total_time)
        setExpandedProgress(expanded);

        let progressArray = Object.keys(expanded).map(elem => expanded[elem]);
        let cumulativeSum = progressArray.map((sum => value => sum += value)(0));
        setProgressArray(cumulativeSum)

    };

    useEffect(() => {
        setSelectedGoal(goalData)
    }, [])

    useEffect(() => {
        if (!isEmpty(goalData) && goalData) {
            handleResize(goalData)
            window.addEventListener('resize', () => { handleResize(goalData) });
            let verbNumberNoun = goalData.title.split(`in ${goalData.total_time} day`)[0]
            let verb = verbNumberNoun.split(` ${numberWithCommas(goalData.value)} `)[0]
            let noun = verbNumberNoun.split(` ${numberWithCommas(goalData.value)} `)[1]

            let now = new Date();
            let endDate = new Date(goalData.due_date);
            let daysLeft = (endDate.getTime() - now.getTime()) / 1000 / 86400;
            let perDayValue = Math.max(1, (goalData.value - goalData.total_completed) / daysLeft);
            perDayValue = perDayValue < 1 ? Number.parseFloat(perDayValue).toFixed(1) : Math.round(perDayValue);
            let daytext = `${verb} ${perDayValue} ${noun} per day to complete your Grid Goal`;
            setPerDayText(daytext)
            let expanded = expandProgress(goalData.progress, goalData.total_time)
            setExpandedProgress(expanded);

            let progressArray = Object.keys(expanded).map(elem => expanded[elem]);
            let cumulativeSum = progressArray.map((sum => value => sum += value)(0));
            setProgressArray(cumulativeSum)
        }
        else {
            setPerDayText("")
        }

    }, [goalData])

    return (
        <div className="grid-goal-container">
            <div className="titles-container" >
                <div className="current-outcome-title">
                    {!selectedOutcome || isEmpty(selectedOutcome) ? "" : selectedOutcome.title}
                </div>
                <div className="grid-goal-title">
                    {goalData && goalData.id !== -1 ? goalData.title : "Click the '+' to create your first Grid Goal"}
                </div>
            </div>



            <div className="grid-goal-header-container">
                
                {selectedOutcome && selectedOutcome.outcome_id && goalDatas[selectedOutcome.outcome_id].length > 0 ?
                    <Form submitForm={submitForm} /> : <></>}
            </div>

            <h4 className="preview-text-per-basis">
                {goalData && goalData.id !== -1 ? perDayText : ""}
            </h4>
            <div className="grid-goal-body-content-container">
                <div className="grid-goal-body-content">
                    { goalData && selectedOutcome.outcome_id && goalDatas[selectedOutcome.outcome_id].length > 0 ? [...Array(goalData.num_cells).keys()].map(function (numCell) {

                        let progress = goalData.progress;
                        let counter = (numCell) * goalData.multiplier;

                        // if the the current counter is less than the the sum, set the opacity to the keyof the progress object
                        // let progressArray = Object.keys(progress).map(elem => progress[elem]);


                        // let cumulativeSum = progressArray.map((sum => value => sum += value)(0));
                        let cumulativeSumLength = progressArray.length;
                        let day = 0;
                        for (let i = 0; i < cumulativeSumLength; i++) {
                            if (counter < progressArray[i]) {
                                day = i;
                                break;
                            }
                        }


                        // setCurrentSum(progressArray[currentSumIndex]);


                        // let total_completed = .map(elem => progress[elem]).reduce((partial_sum, a) => partial_sum + a, 0);
                        // This is where I left off. Its supposed to break so it errors. 

                        return (
                            <GridgoalCell
                                key={numCell}
                                index={numCell}
                                multiplier={goalData.multiplier}
                                num_cells={goalData.num_cells}
                                completedTotal={goalData.total_completed}
                                total={goalData.value}
                                day={day}
                            />)

                    }) : <></>}
                </div>
            </div>
        </div>
    )
}

export default Gridgoal
