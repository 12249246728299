import React, { useState, useEffect } from 'react'
import { scaleBand, scaleLinear, format } from 'd3';
import * as d3 from 'd3';
import { numberWithCommas,expandProgress,getTimeRemaining } from '../../utils/utils';
// import ProjectionLine from '../Analytics/ProjectionLine'
// import WaitingIndicator from '../../../../general-ui/waiting-indicator/waiting-indicator';

import Line from './Line';
import { line, curveLinear } from 'd3-shape';
import { extent } from 'd3-array';
import { transition } from 'd3-transition';




const margin = { top: 20, right: 15, bottom: 30, left: 60 };
const siFormat = format('.2s');
// Formats used to style different numbers and values. Will be changed/removed when live data is used
const yAxisTickFormat = (tickValue) => tickValue //< 1 ? tickValue : siFormat(tickValue).replace('G', 'B');
const xAxisTickFormat = (tickValue) => tickValue * 100 + "%" //{ return tickValue? tickValue.replace("United States of America", "USA"): ''; };
const xValue = (d) => d.dayNum;
const yValue = (d) => parseFloat(d.progress);
const hoverColor = "#eec42d";
const staticColor = '#437c90';

function randomString() {
    return (Math.random() + 1).toString(36).substring(7);
}

// function yScale(maxOfData,innerHeight) {
//     return scaleLinear().domain([0, maxOfData]).range([innerHeight, 0])
// };

// Creates the elements for the bottom axis
const AxisBottom = ({ xScale, innerHeight, innerWidth, tickFormat }) =>
    <g>
        <line x1="0" y1={innerHeight} x2={innerWidth} y2={innerHeight} stroke="#000" strokeWidth="4" />
        {xScale.domain().map((tickValue) => (
            <g
                className="barchart-tick"
                key={randomString()}
            >
                <line
                    x1={xScale(tickValue)}
                    y1={innerHeight}
                    x2={xScale(tickValue)}
                    y2={innerHeight + 6} stroke="#000" strokeWidth="4" />

                <line
                    x1={xScale(tickValue)}
                    y1={0}
                    x2={xScale(tickValue)}
                    y2={innerHeight - 2} stroke="#aaa" strokeWidth="1" />

                <text
                    x={xScale(tickValue) +
                        xScale.bandwidth() / 2}
                    y={innerHeight + 6 + margin.bottom / 2}
                    style={{ textAnchor: "middle", fontSize: "10px" }}
                >
                    {tickFormat(tickValue)}
                </text>
            </g>
        ))}
    </g>



// Creates the elements for the left axis
const AxisLeft = ({ yScale, tickFormat, innerWidth, leftAxis, innerHeight }) =>
    <g>
        <line x1="0" y1="-2" x2="0" y2={innerHeight + 2} stroke="#000" strokeWidth="4" />
        {
            leftAxis.map((tickValue) => (
                <g
                    className="barchart-tick"
                    key={randomString()}
                    transform={`translate(0,${yScale(tickValue)})`}
                >
                    <line x1="0" y1="0" x2="-5" y2="0" stroke="#000" strokeWidth="4" />
                    {/* <line x2={innerWidth} /> */}

                    <text
                        x={-10}
                        // y={yScale(tickValue) +
                        //     yScale.bandwidth() / 2}
                        style={{ textAnchor: 'end', fontSize: "10px" }}

                    >
                        {tickFormat(tickValue)}
                    </text>
                </g>

            ))}
    </g>


function ProjectionLine({ yScale, lineAverage }) {
    const lineHeight = yScale(lineAverage);
    return (
        <g >
            <Line x1="0" y1={lineHeight} x2="100%" y2={lineHeight} stroke="#FF5353" strokeWidth="3"
                strokeDasharray="4" />
        </g>
    );
}


function LineChart({ width, height, dataIn, loading, error, maxHeight, totalDays, goal }) {
    const formatedData = expandProgress(dataIn, totalDays);
    const remainingObj = getTimeRemaining(goal);

    let md = [];
    for (let [key, value] of Object.entries(formatedData)) {
        md.push({
            dayNum: parseFloat(key),
            progress: value
        })
    }

    let sum = 0;
    const initialData = [];

    for (let i = 0; i < md.length; i++) {
        sum += md[i].progress
        initialData.push({
            dayNum: md[i].dayNum,
            progress: sum
        })        
    }
    maxHeight = Math.max(sum,maxHeight);

    let innerWidth = width - margin.left - margin.right;
    let innerHeight = height - margin.top - margin.bottom;

    let xsData = scaleBand().domain(md.map(xValue)).range([0, innerWidth]).paddingInner(1); // Adding ".paddingInner(1)" puts the grapg on the correct phase position
    let xsDividor = scaleBand().domain([0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1]).range([0, innerWidth]).paddingInner(1);

    let ys = scaleLinear().domain([0, maxHeight+maxHeight*.2]).range([innerHeight, 0]);

    const [modifiedData, setModifiedData] = useState(md);
    const [xScale, setXScale] = useState(xsData);
    const [yScale, setYScale] = useState(ys);

    const [leftAxis, setLeftAxis] = useState(ys.ticks(5));
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        const formatedData = expandProgress(dataIn, totalDays)
        let md = [];
        for (let [key, value] of Object.entries(formatedData)) {
            md.push({
                dayNum: parseFloat(key),
                progress: value
            })
        }

        let maxProgress = Math.max(...md.map((o) => o.progress), 0);
        let xs = scaleBand().domain(md.map(xValue)).range([0, innerWidth]).paddingInner(1);
        let ys = scaleLinear().domain([0, maxHeight]).range([innerHeight, 0]);

        setModifiedData(md);
        setXScale(xs);
        setYScale(ys);
        setLeftAxis(ys.ticks(5));
        setLoaded(true);

    }, [dataIn])


    const lineGenerator = line()
        .x(d => xsData(d.dayNum))
        .y(d => ys(d.progress))
        .curve(curveLinear)
        .defined((d => d.dayNum <= remainingObj.dayNum));


    const areaPath = d3
        .area()
        .x((d) => xsData(d.dayNum))
        .y0((d) => ys(d.progress))
        .y1(() => ys(0 - 1))
        .defined((d => d.dayNum <= remainingObj.dayNum))
        .curve(d3.curveLinear)(initialData);



    // Piece it all together
    return (
        <div id="line-chart-container">
            <svg className="lineChart" width={width} height={height} id={`retention-chart`}>
                <g key={randomString()} transform={`translate(${margin.left},${margin.top})`}>

                    <AxisBottom
                        xScale={xScale || xsDividor}
                        innerHeight={innerHeight}
                        tickFormat={xAxisTickFormat}
                        innerWidth={innerWidth}
                    />

                    <AxisLeft
                        yScale={yScale || ys}
                        tickFormat={yAxisTickFormat}
                        innerWidth={innerWidth}
                        leftAxis={leftAxis}
                        innerHeight={innerHeight}
                    />

                    <Line data={initialData} xScale={xScale || xsData} yScale={yScale || ys} lineGenerator={lineGenerator} width={width} height={height} dayNum = {remainingObj.dayNum}/>

                    <path fill="#a4a4a4" d={areaPath} opacity={0.3} />
                    {/* <Marks
                        data={modifiedData}
                        xScale={xScale || xsData}
                        yScale={yScale || ys}
                        xValue={xValue}
                        yValue={yValue}
                        innerWidth={innerWidth}
                        innerHeight={innerHeight}
                    /> */}

                    {/* <ProjectionLine
                        yScale={yScale || ys}
                        lineAverage={lineAverage}
                    /> */}
                </g>
            </svg>
        </div>

    )
}

export default LineChart;