
export function numberWithCommas(x) {
    if (!x) {
        return x
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function mapNumber(x, in_min, in_max, out_min, out_max) {
    return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export const randomGoals = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};

export const getGridMultiplier = (num) => {
    // The value is entered as a string
    const gridMultiplier = num > 200 ? Math.ceil(num / 200) : 1;
    return gridMultiplier
}

export const getNumColumns = (cellCount, bw, bh) => {
    // let allowedColumnNums = [1, 2, 3, 4, 5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 100];

    let proportion = bh / bw;
    // let allowedColumnNums = [1, 2, 3, 4, 5, 10, 20, 25, 30];
    let allowedColumnNums = [...Array(30).keys()];

    let x = Math.floor(Math.sqrt(cellCount / proportion));

    const closest = allowedColumnNums.reduce((prev, curr) =>
        Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev
    );

    // console.log(`closest, x`, closest, x, cellCount)

    return closest
}
export const getGridDims = (value) => {
    // The value is entered as a string

    let allowedColumnNums = [1, 2, 3, 4, 5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 100];

    let num = value;
    const gridMultiplier = num > 200 ? Math.ceil(num / 200) : 1;
    num = Math.ceil(num / gridMultiplier);


    let ratio = 1 / 1.3;

    let x = Math.sqrt(num / ratio);


    const closest = allowedColumnNums.reduce((prev, curr) =>
        Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev
    );

    let y = Math.ceil(num / closest);
    return [closest, y, num, gridMultiplier];
};


export function saveToLocal(storageKey, data) {
    localStorage.setItem(storageKey, JSON.stringify(data))
}

export function getFromLocal(storageKey, defaultValue) {

    // return {}
    const localStorageData = localStorage.getItem(storageKey)
    if (IsJsonString(localStorageData) && localStorageData) {
        return JSON.parse(localStorageData)
    }
    else if (localStorageData) {
        return localStorageData
    }
    else {
        return defaultValue
    }
    // return JSON.parse(localStorage.getItem(storageKey)) || defaultValue;
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getTimeRemaining(goalData) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    let endDate = new Date(goalData.due_date);
    let now = new Date();
    let timeLeft = (endDate.getTime() - now.getTime()) / 1000 / 86400
    let endMonth = monthNames[endDate.getMonth()]

    let endDay = endDate.getDate()

    let remainingTime = timeLeft;

    let daysRemaining = Math.floor(timeLeft);
    let hoursRemaining = Math.round((timeLeft - Math.floor(timeLeft)) * 24);

    let totalDays = goalData.total_time;
    // let dayNum = daysLeft
    let dayNum = Math.floor(totalDays - daysRemaining - 1);

    return { daysRemaining, hoursRemaining, endMonth, endDay, remainingTime, dayNum }
}

export function expandProgress(input, totalDays) {
    let expanded = {};
    for (let index = 0; index < totalDays; index++) {
        expanded[index] = input[index] ? input[index] : 0;
    }
    return expanded
}

export function isEmpty(obj) {
    for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export function randomString() {
    return (Math.random() + 1).toString(36).substring(7);
}