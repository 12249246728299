import React, { useState, useEffect } from 'react'
import ModalGridgoal from '../Components/ModalGridgoal';
import ActiveGridGoal from "../Components/ActiveGridGoal";
import Gridgoal from "../Components/Gridgoal";


// import goalsExample from "./data/goalsExample.json";
import { addOpenModalListener, addCloseModalListener } from "../utils/initModal";
import defaultActivity from "../data/defaultActivity.json";
import { saveToLocal, getFromLocal, numberWithCommas, isEmpty } from "../utils/utils";
import axios from 'axios';
import { useGoalData, useSelectedGoalData } from '../Context/GoalDataProvider'
import { useLogin } from '../Context/LoginProvider';
import { recordAction } from '../apiRequests/auth';
import ALL_GLOBAL_VARIABLES from "../global";

import { useOutcomeData, useSelectedOutcomeData } from '../Context/OutcomeDataProvider';
import { exampleOutcomes } from '../data/exampleOutcomes';
import { exampleGoals } from '../data/exampleGoals'

import BarChart from '../Components/Analytics/BarChart';
import LineChart from '../Components/Analytics/LineChart';
import Analytics from './Analytics';
import TemplateContainer from '../Components/TemplateContainer';
import gridGoalsSelectIcon from '../assets/mobile-icons/select.png';
import gridGoalsDisplayIcon from '../assets/mobile-icons/grid.png';
import gridGoalsAnalyticsIcon from '../assets/mobile-icons/analytics.png';

function Gridgoals() {
  const [goalDatas, setGoalDatas] = useGoalData(); // An array of ALL goal data
  const [outcomeDatas, setOutcomeDatas] = useOutcomeData();

  const [selectedGoal, setSelectedGoal] = useSelectedGoalData();
  const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();

  const [loggedIn, setLoggedIn] = useLogin();

  const [newGoalForm, submitNewGoalForm] = useState('');
  const [deleteGridgoalID, setDeleteGridgoalID] = useState('');
  const [incomingGoalFormData, setIncomingGoalFormData] = useState(false);
  const [refreshCharts, setRefreshCharts] = useState(false);
  const [loadGoalData, setLoadGoalData] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false);
  const [runModals, setRunModals] = useState(false);
  const [run, setRun] = useState(true)
  const [exampleOutcome, setExampleOutcome] = useState('')

  const [mobileActiveNav, setMobileActiveNav] = useState(1);

  // Analytics
  const [lineAverage, setLineAverage] = useState(0);

  function saveUpdatedGoals(data) {
    const user_id = getFromLocal("user_id", -1)

    if (user_id !== -1) {
      axios.post(`${ALL_GLOBAL_VARIABLES.host}/update_goals_by_outcome_id`,
        {
          "user_id": user_id,
          "grid_data": data
        }
      ).then((response) => {
      })
    }
  }

  function removeGoalId(id_to_remove, outcome_id, gg_group) {
    const user_id = getFromLocal("user_id", -1)
    if (user_id !== -1) {
      axios.post(`${ALL_GLOBAL_VARIABLES.host}/remove-goal-id`,
        {
          "outcome_id": outcome_id,
          "id_to_remove": id_to_remove,
          "gg_group": gg_group
        }
      ).then((response) => {
        let new_id_array = response.data;

        let sel_id = selectedOutcome.outcome_id;

        for (let i = 0; i < outcomeDatas.length; i++) {
          if (outcomeDatas[i].outcome_id === sel_id) {
            outcomeDatas[i].active_gridgoal_ids = new_id_array
          }
        }
        setOutcomeDatas([...outcomeDatas]);
        saveToLocal("outcome-activity-data", [...outcomeDatas])
      })
    }
  }

  // function loadUserData(user_id, username, outcome_id) {
  //   axios.post(`${ALL_GLOBAL_VARIABLES.host}/all-goals-by-outcome-id`,
  //     {
  //       "username": username,
  //       "user_id": user_id,
  //       "gg_set": outcome_id
  //     }
  //   ).then((response) => {
  //     setGoalDatas(response.data);
  //     setFilteredGoals(() => {
  //       let gs = [...response.data];
  //       for (let i = gs.length; i < 4; i++) {
  //         gs.push(defaultActivity)
  //       }
  //       return gs;
  //     })
  //     setSelectedGoal(response.data[0] || defaultActivity);
  //     setLoadGoalData(true);
  //     setIsLoaded(true);
  //     return response.data
  //   })
  // }

  function updateGridgoalsInOutcome(outcome_id, gridgoal_id) {
    const user_id = getFromLocal("user_id", -1)
    if (user_id !== -1) {
      axios.post(`${ALL_GLOBAL_VARIABLES.host}/update-gridgoal-id-in-outcome`,
        {
          "outcome_id": outcome_id,
          "gridgoal_id": gridgoal_id
        }
      ).then((response) => {

      })
    }
  }

  function handleClick(param) {
    setSelectedGoal({ ...param })
    handleNavIconClick(2)
    // param is the argument you passed to the function
    // e is the event object that returned
  };

  const [filteredGoals, setFilteredGoals] = useState(() => {

    let gs = selectedOutcome.outcome_id && !isEmpty(goalDatas) ? [...goalDatas[selectedOutcome.outcome_id]] : [];
    for (let i = gs.length; i < 4; i++) {
      gs.push(defaultActivity)
    }
    return gs;
  });

  const addCreateGoal = (new_goal, outcome_id, gg_group) => {
    const user_id = getFromLocal("user_id", -1)
    axios.post(`${ALL_GLOBAL_VARIABLES.host}/create-new-goal`,
      {
        "user_id": user_id,
        "new_goal": new_goal,
        "outcome_id": outcome_id,
        'gg_group': gg_group
      }).then((response) => { });
  }

  useEffect(() => {
    const user_id = getFromLocal("user_id", -1)
    const username = getFromLocal("username", "")

    // Check if the selected outcome is empty and if it is, then select the first of outcomeDatas
    if (isEmpty(selectedOutcome) && !isEmpty(outcomeDatas)) {
      setSelectedOutcome(outcomeDatas[0]);
      saveToLocal("selected-outcome", outcomeDatas[0])
    }


    if (user_id !== -1 && username !== "" && selectedOutcome && !isEmpty(selectedOutcome)) {
      // loadUserData(user_id, username, selectedOutcome.active_gridgoal_ids);
      let goals = goalDatas[selectedOutcome.outcome_id];

      // setSelectedGoal(goals.length > 0 ? goalDatas[selectedOutcome.outcome_id][0]: {})
    }
    // I SHOULDNT HAVE TO LOAD USER DATA HERE. IT SHOULD BE IN LOCAL STORAGE

    setRunModals(true);
  }, [])

  // useEffect(() => {

  //   if (goalDatas[selectedOutcome.outcome_id] !== "" && loadGoalData) {

  //     // let allGoals = {...goalDatas}
  //     // let goals = {...goalDatas[selectedOutcome.outcome_id]};

  //     // let removedArray = goals.filter(el => el.id !== deleteGridgoalID);

  //     // allGoals[selectedOutcome.outcome_id] = removedArray 

  //     if()
  //     let allGoals = {...goalDatas}
  //     let goals = {...goalDatas[selectedOutcome.outcome_id]};


  //     let goals = [...goalDatas[selectedOutcome.outcome_id]];



  //     saveToLocal("grid-goal-activity-data", goals);
  //     setGoalDatas(goals);

  //     let gs = [...goals];
  //     for (let i = gs.length; i < 4; i++) { // Change the number of open grid goals to 6
  //       gs.push(defaultActivity)
  //     }

  //     setSelectedGoal(gs[0]);//This is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
  //     setFilteredGoals(gs);
  //     setLoadGoalData(false);
  //   }
  // }, [loadGoalData, goalDatas])

  useEffect(() => {
    setFilteredGoals(filteredGoals);
    setRefreshCharts(false);
  }, [refreshCharts])


  // NEED TO REMOVE DELETEID FROM OUTCOME
  useEffect(() => {
    if (deleteGridgoalID !== "" && deleteGridgoalID) {
      let allGoals = { ...goalDatas }
      let goals = [...goalDatas[selectedOutcome.outcome_id]];

      console.log(`goals`, goals)
      let removedArray = goals.filter(el => el.id !== deleteGridgoalID);

      allGoals[selectedOutcome.outcome_id] = removedArray

      setGoalDatas(allGoals);
      saveUpdatedGoals(removedArray);

      removeGoalId(deleteGridgoalID, selectedOutcome.outcome_id, selectedOutcome.active_gridgoal_ids);

      let gs = [...removedArray];
      for (let i = gs.length; i < 4; i++) {
        gs.push(defaultActivity)
      }
      if (gs.filter(el => el.id !== -1).length === 0) {
        setSelectedGoal()
      }
      setFilteredGoals(gs)
      setDeleteGridgoalID("");
      // setRunModals(true);

      recordAction('Delete Gridgoal');
      saveToLocal("grid-goal-activity-data", allGoals);

      // // selectedOutcome.outcome_id
      // let tempOutcome = [...outcomeDatas];
      // let gg_group = ''
      // let deletedId = 0

      // // console.log(`tempOutcome`, tempOutcome)
      // for (let i = 0; i < tempOutcome.length; i++) {
      //   if (tempOutcome[i].outcome_id == deleteGridgoalID) {
      //     deletedId = i;
      //   }
      // }
      // // console.log(`tempOutcome`, tempOutcome)

      // for (let i = 0; i < removedArray.length; i++) {
      //   gg_group += ` ${removedArray[i].id}`

      // }

      // tempOutcome[deletedId].active_gridgoal_ids = gg_group;

      // // console.log(`tempOutcome`,tempOutcome, gg_group);

      // saveToLocal("outcome-activity-data", tempOutcome);


      // Update the outcomeDatas.outcome_id.active_gridgoal_ids and 
      // update the active_gridgoal_ids value in the db where outcome_id == the selected outcome.
      // Add the outcome array to the database. 

      // outcomeDatas
      // setSelectedOutcome
      // setOutcomeDatas

    }
  }, [deleteGridgoalID, goalDatas])


  useEffect(() => {
    if (newGoalForm !== "" && incomingGoalFormData) {
      const verb = newGoalForm[0];
      const number = Number(newGoalForm[1]);
      const noun = newGoalForm[2];
      const duration = Number(newGoalForm[3]);
      const type = newGoalForm[4];

      const multiplier = number >= 200 ? Math.ceil(number / 200) : 1;


      let goals = selectedOutcome.outcome_id ? [...goalDatas[selectedOutcome.outcome_id]] : [];

      let today = new Date();
      let endDate = new Date(today.setDate(today.getDate() + duration)).toString();

      let emptyProgress = {};
      let newGoal = {
        id: Math.random().toString(36).substr(2, 9), // Generates a random 8 digit id
        due_date: endDate,
        icon: type,
        multiplier: multiplier,
        num_cells: Math.ceil(Number(number) / multiplier),
        status: 1,
        title: `${verb} ${numberWithCommas(number)} ${noun} in ${duration} days`,
        total_completed: 0,
        total_time: duration,
        value: number,
        progress: emptyProgress,
        verb: verb,
        number: number,
        noun: noun,
        duration: duration
        // progress:{...[...Array(duration).keys()].map((elem) => (elem,0))}
      }

      if (isEmpty(selectedOutcome)) {
        selectedOutcome = getFromLocal("selected-outcome");
      }





      addCreateGoal(newGoal, selectedOutcome.outcome_id)
      goals.push(newGoal)

      goalDatas[selectedOutcome.outcome_id] = goals


      saveUpdatedGoals(goals)


      // Get new goal id
      saveToLocal("grid-goal-activity-data", goalDatas);



      setGoalDatas(goalDatas);

      let gs = [...goals];
      for (let i = gs.length; i < 4; i++) {
        gs.push(defaultActivity)
      }

      setSelectedGoal(newGoal)//THis is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
      setFilteredGoals(gs)
      setIncomingGoalFormData(false)


      updateGridgoalsInOutcome(selectedOutcome.outcome_id, `${selectedOutcome.active_gridgoal_ids} ${newGoal.id}`)


      let tempSelectedOutcome = { ...selectedOutcome };

      tempSelectedOutcome.active_gridgoal_ids = `${selectedOutcome.active_gridgoal_ids} ${newGoal.id}`

      saveToLocal("selected-outcome", tempSelectedOutcome);
      setSelectedOutcome(tempSelectedOutcome)


      let tempOutcomeData = [...outcomeDatas];
      let selectedId = 0;
      for (let i = 0; i < tempOutcomeData.length; i++) {
        if (tempOutcomeData[i].outcome_id == selectedOutcome.outcome_id) {
          selectedId = i;
        }
      }

      tempOutcomeData[selectedId] = tempSelectedOutcome;

      setOutcomeDatas(tempOutcomeData);

      saveToLocal("outcome-activity-data", tempOutcomeData);


      // Add the new ID to the selected outcome
      // Add the updated outcome to the outcome array,


      // Update the outcomeDatas.outcome_id.active_gridgoal_ids and 
      // update the active_gridgoal_ids value in the db where outcome_id == the selected outcome.
      // Add the outcome array to the database. 

      // outcomeDatas
      // setSelectedOutcome
      // setOutcomeDatas

    }
  }, [newGoalForm, goalDatas, incomingGoalFormData])

  useEffect(() => {
    if (runModals) {
      document.querySelectorAll("[data-modal-event]").forEach(addOpenModalListener);
      // Close the modal when the user clicks the close button or somewhere outside of the main modal content
      document.querySelectorAll(".modal__wrapper").forEach(addCloseModalListener);

      setRunModals(false);
    }

    setRun(true)
  }, [runModals, filteredGoals])

  useEffect(() => {
    if (loggedIn === false) {

      let goals = selectedOutcome.outcome_id ? [...goalDatas[selectedOutcome.outcome_id]] : [];
      let gs = [...goals];

      for (let i = gs.length; i < 4; i++) {
        gs.push(defaultActivity)
      }

      // setSelectedGoal(newGoal)//THis is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
      setFilteredGoals([...gs])
      setIncomingGoalFormData(false)
    }
  }, [loggedIn])


  useEffect(() => {
    setRunModals(true);
  }, [filteredGoals, loadGoalData, goalDatas])

  useEffect(() => {
    setSelectedGoal(() => {
      let goals = selectedOutcome.outcome_id ? [...goalDatas[selectedOutcome.outcome_id]] : [];
      return goals[0]
    })
  }, [])


  useEffect(() => {
    let navItemActive = document.querySelector(`.mobile-nav-item-container.active`);
    navItemActive.classList.remove('active')
    let navItem = document.querySelector(`.mobile-nav-item-container:nth-child(${mobileActiveNav})`)
    navItem.classList.add("active")

    let selectedContainer = "";
    if (mobileActiveNav === 1) { selectedContainer = `.active-grid-goals-container`; }
    if (mobileActiveNav === 2) { selectedContainer = `.grid-goal-container`; }
    if (mobileActiveNav === 3) { selectedContainer = `.analytics-container`; }

    document.querySelector(`.active-grid-goals-container`).classList.remove('active')
    document.querySelector(`.grid-goal-container`).classList.remove('active')
    document.querySelector(`.analytics-container`).classList.remove('active')


    let selectedContainerElement = document.querySelector(selectedContainer); 
    selectedContainerElement.classList.add("active")


  }, [mobileActiveNav])

  const handleNavIconClick = (index) => {
    setMobileActiveNav(index)
  }
  return (
    <>
      <div className="page-content">
        {/* <TemplateContainer setExampleOutcome={setExampleOutcome} exampleData={exampleGoals.filter(goal => goal.type === selectedOutcome.outcome_type)} modalType={"new-grid-goal"} /> */}
        <div className="mobile-grid-goal-nav">
          <div className="mobile-nav-item-container active" onClick={() => handleNavIconClick(1)}>
            <img src={gridGoalsSelectIcon} alt="gridGoalsSelectIcon" />
          </div>
          <div className="mobile-nav-item-container" onClick={() => handleNavIconClick(2)}>
            <img src={gridGoalsDisplayIcon} alt="gridGoalsDisplayIcon" />
          </div>
          <div className="mobile-nav-item-container" onClick={() => handleNavIconClick(3)}>
            <img src={gridGoalsAnalyticsIcon} alt="gridGoalsAnalyticsIcon" />
          </div>
        </div>
        <div className="active-grid-goals-container active">
          {
            filteredGoals.map((goalData, i) =>
              <ActiveGridGoal key={i} onclick={handleClick} goalData={{ ...goalData }} submitNewGoalForm={submitNewGoalForm} setIncomingGoalFormData={setIncomingGoalFormData} setDeleteGridgoalID={setDeleteGridgoalID} />
            )
          }
        </div>
        <Gridgoal goalData={selectedGoal} setRefreshCharts={setRefreshCharts} />

        <div className="analytics-container">
          {selectedGoal ?
            <div className="graph-containers">

              <BarChart width={350} height={200} dataIn={selectedGoal?.progress} loading={true} error={"No data to show"} lineAverage={lineAverage} totalDays={selectedGoal.total_time} />
              <LineChart width={350} height={200} dataIn={selectedGoal?.progress} loading={true} error={"No data to show"} totalDays={selectedGoal.total_time} maxHeight={selectedGoal.value} goal={selectedGoal} />
            </div> :
            <></>
          }
        </div>


      </div>
      {/*:<div className="loading-data"></div> */}

      <ModalGridgoal submitNewGoalForm={submitNewGoalForm} dataModalEvent={"new-grid-goal"} setIncomingGoalFormData={setIncomingGoalFormData} exampleGoal={exampleOutcome} />
    </>
  )
}

export default Gridgoals
// a = [
//   { "id": 32, "outcome_id": "sms72soqr", "title": "Write a Book", "duration": null, "start_date": "2022-01-04T04:15:26.000Z", "end_date": "End of the year", "active_gridgoal_ids": "", "completed_grid_goal_ids": "", "all_gridgoal_ids": "", "outcome_type": "mental", "status": 1 },
//   { "id": 35, "outcome_id": "5iffpl90v", "title": "Lose 30 Pounds", "duration": null, "start_date": "2022-01-05T06:01:33.000Z", "end_date": "In 6 months", "active_gridgoal_ids": "ygmvoqw39 d5hfj7149 ej0cc5ek4", "completed_grid_goal_ids": "", "all_gridgoal_ids": "", "outcome_type": "physical", "status": 1 },
//   { "id": 69, "outcome_id": "qfu7648k5", "title": "Start a Startup", "duration": null, "start_date": "2022-01-09T00:30:15.000Z", "end_date": "In 6 months", "active_gridgoal_ids": "", "completed_grid_goal_ids": "", "all_gridgoal_ids": "", "outcome_type": "social", "status": 1 },
//   { "outcome_id": "a8fcs3rq3", "outcome_type": "social", "status": 1, "title": "Gain 10,000 Followers on Twitter", "end_date": "1 year", "active_gridgoal_ids": " ric9ihp01 d4l0wh9ib" }]