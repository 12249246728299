import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function Line({ xScale, yScale, data, lineGenerator,dayNum }) {

    const lineRef = useRef(null)

    useEffect(() => {

        if (!lineRef.current) {
            d3.select('.line-group')
                .append('path')
                .datum(data)   
                .attr('id', 'line')
                .attr('stroke', 'black')
                .attr('stroke-width', 2)
                .attr('fill', 'none')
                .attr('d', lineGenerator)
                .attr('ref', lineRef)


            // const t = d3.transition().duration(1000);

            // const line = d3.select('#line');

            // line
            //     .datum(data)
            //     .transition(t)
            //     .attr('d', lineGenerator);
        }
    }, [])


    return <g className="line-group" />;
}

export default Line

