import React, { useContext, useState, useEffect } from 'react';
import goalsExample from "../data/goalsExample.json";
import { saveToLocal, getFromLocal } from "../utils/utils";


const OutcomeDataContext = React.createContext();
const OutcomeDataUpdateContext = React.createContext();
const SelectedOutcomeDataContext = React.createContext();
const SelectedOutcomeDataUpdateContext = React.createContext();


export function useOutcomeData() {
    return [useContext(OutcomeDataContext), useContext(OutcomeDataUpdateContext)]
}

export function useSelectedOutcomeData() {
    return [useContext(SelectedOutcomeDataContext), useContext(SelectedOutcomeDataUpdateContext)]
}



export function OutcomeDataProvider({ children }) {
    const [outcomeDatas, setOutcomeDatas] = useState(() => {
        // getting stored value
        const os = JSON.parse(localStorage.getItem("outcome-activity-data")) || [];
        // Make any changes here
        // The outcomes may not exists
        // saveToLocal("outcome-activity-data", gs);
        return os;
    })

    const [selectedOutcome, setSelectedOutcome] = useState(() => {
        // getting stored value
        const os =  getFromLocal("outcome-activity-data",{});
        if(os.length > 0){
            return {}
        }

        // Make any changes here
        // The outcomes may not exists
        // saveToLocal("outcome-activity-data", gs);
        return os;
    })
        
        

    return (
        <OutcomeDataContext.Provider value={outcomeDatas}>
            <OutcomeDataUpdateContext.Provider value={setOutcomeDatas}>
                <SelectedOutcomeDataContext.Provider value={selectedOutcome}>
                    <SelectedOutcomeDataUpdateContext.Provider value={setSelectedOutcome}>
                        {children}
                    </SelectedOutcomeDataUpdateContext.Provider>
                </SelectedOutcomeDataContext.Provider>
            </OutcomeDataUpdateContext.Provider>
        </OutcomeDataContext.Provider>
    )
}

