import React, { useContext, useState } from 'react';
import { getFromLocal } from "../utils/utils";

const LoginContext = React.createContext();
const LoginUpdateContext = React.createContext();



export function useLogin(){
    return [useContext(LoginContext),useContext(LoginUpdateContext)]
}

export function LoginProvider({ children }) {

    const [loggedIn, setLoggedIn] = useState(
        getFromLocal('user_id') ? true:false || false);
    // const [mainLogin, setMainLogin] = useState(true);
    // const [forgotPassword, setForgotPassword] = useState(false);
    // const [registerUser, setRegisterUser] = useState(false);
    // const [loginError, setLoginError] = useState(false)
    // const [openLogin, setOpenLogin] = useState(false);
    // const [openUser, setOpenUser] = useState(false);

    return (
        <LoginContext.Provider value = {loggedIn}>
            <LoginUpdateContext.Provider value = {setLoggedIn}>
                {children}
            </LoginUpdateContext.Provider>
        </LoginContext.Provider>
    )
}

// export default LoginProvider
