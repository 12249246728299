import React, { useEffect, useState } from 'react';
import '../styles/outcomes.css';
import newGridgoal from '../assets/newGridGoal.png'
import ModalOutcome from '../Components/ModalOutcome';
import Outcome from '../Components/Outcome';
import { useOutcomeData, useSelectedOutcomeData } from '../Context/OutcomeDataProvider';
import { useGoalData, useSelectedGoalData } from '../Context/GoalDataProvider'
import { saveToLocal, getFromLocal, numberWithCommas, randomString } from "../utils/utils";
import ALL_GLOBAL_VARIABLES from "../global";
import axios from 'axios';
import { recordAction, loadUserDataOutcomes } from '../apiRequests/auth';
import { exampleOutcomes } from '../data/exampleOutcomes'
import { addOpenModalListener, addCloseModalListener } from "../utils/initModal";


import TemplateContainer from '../Components/TemplateContainer';

function Outcomes({ setRunModals }) {

    const [newOutcomeForm, submitNewOutcomeForm] = useState('');
    const [incomingGoalFormData, setIncomingGoalFormData] = useState(false);
    const [outcomeDatas, setOutcomeDatas] = useOutcomeData();
    const [selectedGoal, setSelectedGoal] = useSelectedGoalData();
    const [goalDatas, setGoalDatas] = useGoalData();

    
    const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();
    const [deleteOutcomeID, setDeleteOutcomeID] = useState();
    const [activeGGSet, setActiveGGSet] = useState();
    const [incomingOutcomesDBData, setIncomingOutcomesDBData] = useState(false);

    




    const [exampleOutcome, setExampleOutcome] = useState('')

    function removeOutcomeId(id_to_remove) {
        const user_id = getFromLocal("user_id", -1)
        if (user_id !== -1) {
            axios.post(`${ALL_GLOBAL_VARIABLES.host}/remove-outcome-id`,
                {
                    "user_id": user_id,
                    "id_to_remove": id_to_remove
                }
            ).then((response) => {
            })
        }
    }

    const addCreateOutcome = (new_outcome) => {
        const user_id = getFromLocal("user_id", -1)
        axios.post(`${ALL_GLOBAL_VARIABLES.host}/create-new-outcome`,
            {
                "user_id": user_id,
                "new_outcome": new_outcome
            }).then((response) => {

            });
    }



    useEffect(() => {
        if (incomingGoalFormData && newOutcomeForm !== "") {

            const type = newOutcomeForm[0];
            const bhag = newOutcomeForm[1];
            const endDate = newOutcomeForm[2];
            let outcomes = [...outcomeDatas];

            let newOutcome = {
                outcome_id: Math.random().toString(36).substr(2, 9), // Generates a random 8 digit id
                outcome_type: type,
                status: 1,
                title: bhag,
                end_date: endDate,
                active_gridgoal_ids: ""
            }

            addCreateOutcome(newOutcome);
            outcomes.push(newOutcome)


            goalDatas[newOutcome.outcome_id] = [];
            setGoalDatas(goalDatas)
            saveToLocal("grid-goal-activity-data",goalDatas); 
            saveToLocal("outcome-activity-data", outcomes);
            setOutcomeDatas(outcomes);

            
           
            setIncomingGoalFormData(false);

            // NAVIGATE
        }
    }, [incomingGoalFormData, outcomeDatas])


    // useEffect(() => {
    //     const user_id = getFromLocal("user_id", -1);
    //     const username = getFromLocal("username", "");
    //     if (user_id !== -1 && username !== "") {
    //         loadUserDataOutcomes(user_id, username, setIncomingOutcomesDBData, setOutcomeDatas)
    //     }

    // }, [])

    useEffect(() => {
        if (outcomeDatas && outcomeDatas !== "" && incomingOutcomesDBData) {
            let outcomes = [...outcomeDatas];
            saveToLocal("outcome-activity-data", outcomes);
            setOutcomeDatas(outcomes);

            // setSelectedOutcome(os[0]);//This is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
            setIncomingOutcomesDBData(false);

        }
    }, [incomingOutcomesDBData, outcomeDatas])


    useEffect(() => {
        if (deleteOutcomeID !== "" && deleteOutcomeID) {

            let outcomes = [...outcomeDatas];
            let removedArray = outcomes.filter(el => el.outcome_id !== deleteOutcomeID);
            saveToLocal("outcome-activity-data", removedArray);

            setOutcomeDatas(removedArray);
            // saveUpdatedOutcomes(removedArray)
            removeOutcomeId(deleteOutcomeID);

            let os = [...removedArray];
            for (let i = os.length; i < 4; i++) {
                os.push({})
            }
            if (os.filter(el => el.id !== -1).length === 0) {
                setSelectedOutcome()
            }

            // DELETE THE ID FROM THE DATAS ARRAY TOO

            setDeleteOutcomeID("");
            setRunModals(true);

            recordAction('Delete Outcome')

        }
    }, [deleteOutcomeID, outcomeDatas])

    useEffect(() => {
        // saveToLocal("grid-goal-activity-data", [])
        // saveToLocal("outcome-activity-data", []);
        // setGoalDatas([]);
        // setSelectedGoal({})
        setRunModals(true);

    }, [])

    return (
        <div className="outcome-page">

            <TemplateContainer setExampleOutcome={setExampleOutcome} exampleData={exampleOutcomes} modalType={"new-outcome"} />


            <div className="outcome-container-wrapper">
                <div className="page-title">The Life-Changing Outcome</div>
                {/* <div className="page-subtitle">What have you always wanted to accomplish?</div> */}
                <div className="outcome-containers">
                    {outcomeDatas.map((outcomeData, i) =>
                        <Outcome key={i} outcome={outcomeData} index={i} setDeleteOutcomeID={setDeleteOutcomeID} setActiveGGSet={setActiveGGSet} />
                    )}
                </div>

                <div className="new-outcome" data-modal-event="new-outcome">
                    <img className="new-outcome-image" src={newGridgoal} alt="add goal" />
                    <div className="new-outcome-text">Create a Custom New Outcome</div>
                </div>

                {/* <ModalOutcome dataModalEvent={"new-outcome-example"} setIncomingGoalFormData={setIncomingGoalFormData} submitNewOutcomeForm={submitNewOutcomeForm} /> */}

            </div>
            <ModalOutcome dataModalEvent={"new-outcome"} setIncomingGoalFormData={setIncomingGoalFormData} submitNewOutcomeForm={submitNewOutcomeForm} exampleOutcome={exampleOutcome} />

        </div>


    )
}

export default Outcomes
