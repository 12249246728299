import React, { useState, useEffect } from 'react'
import { scaleBand, scaleLinear, format, } from 'd3';
import * as d3 from 'd3';
import { numberWithCommas,expandProgress } from '../../utils/utils';
// import ProjectionLine from '../Analytics/ProjectionLine'
// import WaitingIndicator from '../../../../general-ui/waiting-indicator/waiting-indicator';


const margin = { top: 20, right: 15, bottom: 30, left: 60 };
const siFormat = format('.2s');
// Formats used to style different numbers and values. Will be changed/removed when live data is used
const yAxisTickFormat = (tickValue) => tickValue //< 1 ? tickValue : siFormat(tickValue).replace('G', 'B');
const xAxisTickFormat = (tickValue) => tickValue * 100 + "%" //{ return tickValue? tickValue.replace("United States of America", "USA"): ''; };
const xValue = (d) => d.dayNum;
const yValue = (d) => parseFloat(d.progress);
const hoverColor = "#eec42d";
const staticColor = '#437c90';

function randomString() {
    return (Math.random() + 1).toString(36).substring(7);
}

// function yScale(maxOfData,innerHeight) {
//     return scaleLinear().domain([0, maxOfData]).range([innerHeight, 0])
// };

// Creates the elements for the bottom axis
const AxisBottom = ({ xScale, innerHeight, innerWidth, tickFormat }) =>
    <g>
        <line x1="0" y1={innerHeight} x2={innerWidth} y2={innerHeight} stroke="#000" strokeWidth="4" />
        {xScale.domain().map((tickValue) => (
            <g
                className="barchart-tick"
                key={randomString()}
            >
                <line
                    x1={xScale(tickValue)}
                    y1={innerHeight}
                    x2={xScale(tickValue)}
                    y2={innerHeight + 6} stroke="#000" strokeWidth="4" />

                <line
                    x1={xScale(tickValue)}
                    y1={0}
                    x2={xScale(tickValue)}
                    y2={innerHeight - 2} stroke="#aaa" strokeWidth="1" />

                <text
                    x={xScale(tickValue) +
                        xScale.bandwidth() / 2}
                    y={innerHeight + 6 + margin.bottom / 2}
                    style={{ textAnchor: "middle", fontSize: "10px" }}
                >
                    {tickFormat(tickValue)}
                </text>
            </g>
        ))}
    </g>



// Creates the elements for the left axis
const AxisLeft = ({ yScale, tickFormat, innerWidth, leftAxis, innerHeight }) =>
    <g>
        <line x1="0" y1="-2" x2="0" y2={innerHeight + 2} stroke="#000" strokeWidth="4" />
        {
            leftAxis.map((tickValue) => (
                <g
                    className="barchart-tick"
                    key={randomString()}
                    transform={`translate(0,${yScale(tickValue)})`}
                >
                    <line x1="0" y1="0" x2="-5" y2="0" stroke="#000" strokeWidth="4" />
                    {/* <line x2={innerWidth} /> */}

                    <text
                        x={-10}
                        // y={yScale(tickValue) +
                        //     yScale.bandwidth() / 2}
                        style={{ textAnchor: 'end', fontSize: "10px" }}

                    >
                        {tickFormat(tickValue)}
                    </text>
                </g>

            ))}
    </g>

// Creates the bars for the retention chart using svgs
const Marks = ({ data, xScale, yScale, xValue, yValue, innerHeight }) => {
    // const tooltip = d3
    //     .select('#retention-chart-container')
    //     .append('div')
    //     .attr('class', 'd3-tooltip-retention')
    //     .attr('id', 'd3-tooltip-retention')
    //     .style('position', 'absolute')
    //     .style('z-index', '10')
    //     .style('visibility', 'hidden')
    //     .style('padding', '10px')
    //     .style('background', 'rgba(0,0,0,0.6)')
    //     .style('border-radius', '4px')
    //     .style('color', '#fff')
    //     .text('a simple tooltip');
   

    let colors = ["#E67571","#FEB99C","#FEF89C","#AEF5A2","#9BE2F4","#8BA4FE","#CB8BFE"]
    return (data.map((d) => (
        
        <g key={randomString()}>
            <rect
                className={"barchart-mark"}
                x={xScale(xValue(d))}
                y={yScale(yValue(d))}
                fill={colors[d.dayNum % 7]}

                width={xScale.bandwidth()}
                height={0}//{innerHeight - yScale(yValue(d))}
                height={innerHeight - yScale(yValue(d))}

                rx={0}
                stroke="black"
                onMouseEnter={function () {

                    // tooltip
                    //     .html(`<div>${numberWithCommas(yValue(d).toFixed(0))}</div>`)
                    //     .style('visibility', 'visible');
                    // d3.select(".d3-tooltip-retention").transition().attr('fill', hoverColor);

                }}

                onMouseMove={function (event) {
                    // tooltip
                    //     .style('top', event.pageY - 10 + 'px')
                    //     .style('left', event.pageX + 10 + 'px');
                }}
                onMouseOut={function () {
                    // tooltip.html(``).style('visibility', 'hidden');
                    // d3.select('.d3-tooltip-retention').transition().attr('fill', staticColor);
                }}>
                {/* <animate attributeName="height" from="0" to={innerHeight - yScale(yValue(d))} dur="0.5s" fill="freeze" />
                <animate attributeName="y" from={innerHeight} to={yScale(yValue(d))} dur="0.5s" fill="freeze" /> */}
            </rect>
            <g className={"barchart-mark-percentage"}></g>
        </g>
    )));
};


function ProjectionLine({ yScale, lineAverage }) {
    const lineHeight = yScale(lineAverage);
    return (
        <g >
            <line x1="0" y1={lineHeight} x2="100%" y2={lineHeight} stroke="#FF5353" strokeWidth="3"
                strokeDasharray="4" />
        </g>
    );
}


function BarChart({ width, height, dataIn, loading, error, lineAverage,totalDays }) {
    
    const formatedData = expandProgress(dataIn, totalDays)
    let md = [];
    for (let [key, value] of Object.entries(formatedData)) {
        md.push({
            dayNum: parseFloat(key),
            progress: value
        })
    }

    let maxProgress = Math.max(...md.map((o) => o.progress), 1);
    let innerWidth = width - margin.left - margin.right;
    let innerHeight = height - margin.top - margin.bottom;

    let xsData = scaleBand().domain(md.map(xValue)).range([0, innerWidth]).paddingInner(0);
    let xsDividor = scaleBand().domain([0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1]).range([0, innerWidth]).paddingInner(1);

    let ys = scaleLinear().domain([0, maxProgress]).range([innerHeight, 0]);

    const [modifiedData, setModifiedData] = useState(md);
    const [xScale, setXScale] = useState(xsData);
    const [yScale, setYScale] = useState(ys);

    const [leftAxis, setLeftAxis] = useState(ys.ticks(5));
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        let md2 = [];
        for (let [key, value] of Object.entries(formatedData)) {
            md2.push({
                dayNum: parseFloat(key),
                progress: value
            })
        }

        let maxProgress = Math.max(...md2.map((o) => o.progress), 0);
        let xs = scaleBand().domain(md2.map(xValue)).range([0, innerWidth]).paddingInner(1);
        let ys = scaleLinear().domain([0, maxProgress]).range([innerHeight, 0]);

        // console.log(`md`, md2)
        setModifiedData(md2);
        setXScale(xs);
        setYScale(ys);
        setLeftAxis(ys.ticks(5));
        setLoaded(true);

    }, [dataIn])

    useEffect(()=>{
        
    },[modifiedData])
    // Piece it all together
    return (
        <div id="bar-chart-container">
            <svg className="bar-chart" width={width} height={height} id={`bar-chart`}>
                <g key={randomString()} transform={`translate(${margin.left},${margin.top})`}>
                    
                    <Marks
                        data={md}
                        xScale={xScale || xsData}
                        yScale={yScale || ys}
                        xValue={xValue}
                        yValue={yValue}
                        innerWidth={innerWidth}
                        innerHeight={innerHeight}
                    />
                    <AxisBottom
                        xScale={xScale || xsDividor}
                        innerHeight={innerHeight}
                        tickFormat={xAxisTickFormat}
                        innerWidth={innerWidth}
                    />

                    <AxisLeft
                        yScale={yScale || ys}
                        tickFormat={yAxisTickFormat}
                        innerWidth={innerWidth}
                        leftAxis={leftAxis}
                        innerHeight={innerHeight}
                    />


                    {lineAverage > 1 ?
                        <ProjectionLine
                            yScale={yScale || ys}
                            lineAverage={lineAverage}
                        />
                        : <></>}
                </g>
            </svg>
        </div>

    )
}

export default BarChart;
