import React, { useContext, useState } from 'react';
import { saveToLocal, getFromLocal, isEmpty } from "../utils/utils";


const GoalDataContext = React.createContext();
const GoalDataUpdateContext = React.createContext();
const SelectedGoalDataContext = React.createContext();
const SelectedGoalDataUpdateContext = React.createContext();


export function useGoalData() {
    return [useContext(GoalDataContext), useContext(GoalDataUpdateContext)]
}

export function useSelectedGoalData() {
    return [useContext(SelectedGoalDataContext), useContext(SelectedGoalDataUpdateContext)]
}


export function GoalDataProvider({ children }) {
    const [goalDatas, setGoalDatas] = useState(() => {
        // getting stored value
        const gs = JSON.parse(localStorage.getItem("grid-goal-activity-data")) || {};


        // console.log(`gs[Object.keys(gs)[0]]`, gs[Object.keys(gs)[0]])

        // gs.forEach(g => {
        //     if (!("progress" in g)) {
        //         // Set the progress to an empty object
        //         // let emptyProgress = { ...[...Array(g.total_time).keys()].map((elem) => (elem)) }
        //         // Object.keys(emptyProgress).forEach(v => emptyProgress[v] = 0);
        //         let emptyProgress = {}
        //         g['progress'] = emptyProgress;

        //         // Set today's day number as the total amount
        //         let now = new Date();
        //         let endDate = new Date(g.due_date);
        //         let daysLeft = Math.floor((endDate.getTime() - now.getTime()) / 1000 / 86400);
        //         let totalDays = g.total_time;
        //         // let dayNum = daysLeft
        //         let dayNum = Math.floor(totalDays - daysLeft);
        //         g['progress'][dayNum] = g.total_completed;

        //     }
        // });

        // saveToLocal("grid-goal-activity-data", gs);
        return gs;
    })


    const [selectedGoal, setSelectedGoal] = useState(()=>{
       
        const gs = getFromLocal("grid-goal-activity-data", {})
        
        if(isEmpty(gs)){
            return {}
        }
        return  gs[Object.keys(gs)[0]][0] || {}});


    return (
        <GoalDataContext.Provider value={goalDatas}>
            <GoalDataUpdateContext.Provider value={setGoalDatas}>
                <SelectedGoalDataContext.Provider value={selectedGoal}>
                    <SelectedGoalDataUpdateContext.Provider value={setSelectedGoal}>
                        {children}
                    </SelectedGoalDataUpdateContext.Provider>
                </SelectedGoalDataContext.Provider>
            </GoalDataUpdateContext.Provider>
        </GoalDataContext.Provider>
    )
}

