import React, { useState, useEffect } from "react";

import Navbar from "./Components/Navbar";

// import goalsExample from "./data/goalsExample.json";
import defaultActivity from "./data/defaultActivity.json";
import { saveToLocal, getFromLocal, numberWithCommas } from "./utils/utils";
import axios from 'axios';

import { useLogin } from './Context/LoginProvider';
import { addOpenModalListener, addCloseModalListener } from "./utils/initModal";
import { recordAction, getActiveOutcomes } from './apiRequests/auth';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate 
} from "react-router-dom";

import Gridgoals from './Pages/Gridgoals'
import Outcomes from './Pages/Outcomes'
import Analytics from './Pages/Analytics';

import { useOutcomeData, useSelectedOutcomeData } from './Context/OutcomeDataProvider';
import { useGoalData, useSelectedGoalData } from './Context/GoalDataProvider'

function App() {
  const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();
  const [outcomeDatas, setOutcomeDatas] = useOutcomeData();
  const [goalDatas, setUseGoalDatas] = useGoalData();
  const [runModals, setRunModals] = useState(false);
  // const navigate = useNavigate();
// 

  // all-outcomes-by-id
  // all-goals-by-outcome-id

  // login	
  // all-outcomes-by-id	
  // all-goals-by-outcome-id
  // Uses Context so I don't have to worry about sending it to every single component

  // Check if logged in, then get user data LOAD DATA: 
  useEffect(() => {
    var username = localStorage.getItem("username");
    var user_id = localStorage.getItem("user_id");

    if (username && user_id) {
      getActiveOutcomes(user_id, username, setOutcomeDatas, setSelectedOutcome, saveToLocal, setUseGoalDatas);
    }

    console.log(`object`, window.location.host)
    if(!['/outcomes'].includes(window.location.pathname)){
      console.log(`object`, window.location.protocol)
      window.location.replace(`${window.location.protocol}//${window.location.host}/outcomes`);
    }
  }, [])

  useEffect(() => {
    if (runModals) {
      document.querySelectorAll("[data-modal-event]").forEach(addOpenModalListener);
      // Close the modal when the user clicks the close button or somewhere outside of the main modal content
      document.querySelectorAll(".modal__wrapper").forEach(addCloseModalListener);

      setRunModals(false);
    }
  }, [runModals])
  return (
    <div className="App">



      <Router>
        <Navbar setRunModals={setRunModals} />
        <Routes>
          {/* <Route exact path="/" exact element={<Outcomes />} />
          <Route exact path="/outcomes" element={<Outcomes />} /> */}
          <Route exact path="/gridgoals"
            element={<Gridgoals />}
          />

          <Route exact path="/outcomes" element={<Outcomes
            setRunModals={setRunModals}
          />} />

          <Route exact path="/analytics" element={<Analytics
            setRunModals={setRunModals}
          />} />

          <Route exact path="/" element={<Outcomes
            setRunModals={setRunModals}
          />} />


        </Routes>
      </Router>




    </div>
  );
}

export default App;
