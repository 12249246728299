import React, { useState, useEffect } from 'react';
import dietary from '../assets/goalTypes/dietary.png';
import family from '../assets/goalTypes/family.png';
import financial from '../assets/goalTypes/financial.png';
import mental from '../assets/goalTypes/mental.png';
import physical from '../assets/goalTypes/physical.png';
import social from '../assets/goalTypes/social.png';
import { recordAction } from '../apiRequests/auth';
// import { exampleGoals } from '../data/exampleGoals.js'
import '../styles/newOutcomeForm.scss';
import close_icon from '../assets/closeicon--black.svg'
// import '../styles/newGridGoalInputForm.css';

function ModalOutcome({ submitNewOutcomeForm, setIncomingGoalFormData, exampleOutcome }) {
    const [type, setType] = useState("");
    const [bhag, setBhag] = useState("");
    const [duration, setDuration] = useState("");
    const [currentIndicator, setCurrentIndicator] = useState(1);
    const [hoverType, setHoverType] = useState('');
    const [clickType, setClickType] = useState('');
    const [isExampleOutcome, setIsExampleOutcome] = useState(false)


    useEffect(()=>{
        if(exampleOutcome.title){
            setType(exampleOutcome.type);
            setClickType(exampleOutcome.type)
            setBhag(exampleOutcome.title);
            setDuration(exampleOutcome.duration);
            setIsExampleOutcome(true)
        }
    },[exampleOutcome])

    const handleSubmit = (e) => {
        if (type !== "" && bhag !== "" && duration !== "") {
            e.preventDefault();
            setType("");
            setBhag("");
            setDuration("");
            setCurrentIndicator(1);

            submitNewOutcomeForm([type, bhag, duration]);
            setIncomingGoalFormData(true);

            // e.target.reset()
            document.querySelectorAll(".modal--visible").forEach((el) => {
                el.classList.remove("modal--visible");
            });
            let resetEvent = {
                resetIndicator: 1
            };
            handleClickIndicator(resetEvent);
            // article.dataset.columns
            recordAction('Create Outcome');
            
        }
    }

    const reSetType = (e) => {
        setType(e)
    }

    const reSetBHAG = (e) => {
        setBhag(e.target.value)
    }

    const reSetDuration = (e) => {
        setDuration(e.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === 9) {
            event.preventDefault()
        };

        if (event.key === 'Enter') {
            if (currentIndicator < 3) {
                setCurrentIndicator(Number(currentIndicator) + 1);
                handleClickIndicator(event)
            }
        }
    }

    const handleClickIndicator = (e) => {
        // article.dataset.columns
        let indicator = e.target?.parentNode.dataset.indicator;

        if (indicator) {
            setCurrentIndicator(Number(indicator))
        }
        if (e.key) {
            indicator = currentIndicator + 1;
        }
        if (e.resetIndicator) {
            indicator = e.resetIndicator;
        }
        const clickedElement = document.querySelector(`.indicatior-container:nth-child(${indicator})`);
        const activeElements = document.querySelectorAll(".active-indicator");

        activeElements.forEach((activeElement) => {
            activeElement.classList.remove("active-indicator");
        })

        if (clickedElement && !clickedElement.classList.contains("active-indicator")) {
            clickedElement.classList.add("active-indicator")
        }
    }


    return (
        <section className="section__modal">
            <div className="modal__wrapper js-modal--new-outcome">
                <div className="modal__overflow-wrapper">
                    <div className="modal__content">
                        <div className="modal__close">
                            <img
                                src={close_icon}
                                className="modal__close--icon"
                                alt="Close This Modal"
                            />
                        </div>

                        <div className="new-grid-goal-input-form">
                            <div className="indicator-row-container">

                                <div data-indicator="1" className="indicatior-container active-indicator" >
                                    <div className="indicator red-background" onClick={handleClickIndicator}></div>
                                    <div className="indicator-title" onClick={handleClickIndicator}>Big Goal</div>
                                </div>
                                <div data-indicator="2" className="indicatior-container" >
                                    <div className="indicator blue-background" onClick={handleClickIndicator}></div>
                                    <div className="indicator-title" onClick={handleClickIndicator}>Outcome Image</div>
                                </div>
                                <div data-indicator="3" className="indicatior-container" >
                                    <div className="indicator yellow-background" onClick={handleClickIndicator}></div>
                                    <div className="indicator-title" onClick={handleClickIndicator}>Duration</div>
                                </div>
                            </div>

                            <div className="all-inputs-container">
                                {currentIndicator === 1 ?

                                    <div className="input-container active-input-container" >
                                        <div className="prompt-container">
                                            <div className="prompt-number">1.</div>
                                            <div className="prompt-description"> What Life-Changing Goal do you want to accomplish?</div>
                                        </div>
                                        <div className="input-row-container">

                                            <input
                                                type="text"
                                                className="main-input red-background red-underline"
                                                name="bhag"
                                                placeholder='Enter your life changing goal here...'
                                                required
                                                value={bhag}
                                                autoComplete="off"
                                                onChange={e => reSetBHAG(e)}
                                                onKeyPress={e => handleKeyPress(e)}
                                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}

                                            />
                                            <div className="next-container" data-indicator="2" >
                                                <div className="ok-button" onClick={handleClickIndicator}>OK ✓</div>
                                                <div className="press-enter-text">press <b>Enter</b> ↵</div>
                                            </div>
                                        </div>
                                    </div> : <></>}

                                {currentIndicator === 2 ?
                                    <div className="input-container" >
                                        <div className="prompt-container">
                                            <div className="prompt-number">2.</div>
                                            <div className="prompt-description">What <b>TYPE</b> of goal is this: {hoverType ? hoverType.toUpperCase() : clickType.toUpperCase()}</div>
                                        </div>
                                        <div className="input-row-container">

                                            <fieldset className="image-fieldset"
                                                onChange={e => { reSetType(e.target.id); setClickType(e.target.id) }}
                                                onKeyPress={e => handleKeyPress(e)}
                                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}
                                            >
                                                <div onMouseEnter={(e) => { setHoverType("Dietary") }} onMouseOut={(e) => { setHoverType(null) }} >
                                                    <input type="radio" name="activity" className="main-input sr-only" id="dietary" defaultChecked={isExampleOutcome && exampleOutcome.type === "dietary"}/>
                                                    <label htmlFor="dietary">
                                                        <img src={dietary} alt="dietary" />
                                                    </label>
                                                </div>

                                                <div onMouseEnter={(e) => { setHoverType("Family") }} onMouseOut={(e) => { setHoverType(null) }} >
                                                    <input type="radio" name="activity" className="main-input sr-only" id="family" defaultChecked={isExampleOutcome && exampleOutcome.type === "family"}/>
                                                    <label htmlFor="family">
                                                        <img src={family} alt="family" />
                                                    </label>
                                                </div>

                                                <div onMouseEnter={(e) => { setHoverType("Financial") }} onMouseOut={(e) => { setHoverType(null) }} >
                                                    <input type="radio" name="activity" className="main-input sr-only" id="financial" defaultChecked={isExampleOutcome && exampleOutcome.type === "financial"}/>
                                                    <label htmlFor="financial">
                                                        <img src={financial} alt="financial" />
                                                    </label>
                                                </div>

                                                <div onMouseEnter={(e) => { setHoverType("Mental") }} onMouseOut={(e) => { setHoverType(null) }} >
                                                    <input type="radio" name="activity" className="main-input sr-only" id="mental" defaultChecked={isExampleOutcome && exampleOutcome.type === "mental"}/>
                                                    <label htmlFor="mental">
                                                        <img src={mental} alt="mental" />
                                                    </label>
                                                </div>

                                                <div onMouseEnter={(e) => { setHoverType("Physical") }} onMouseOut={(e) => { setHoverType(null) }} >
                                                    <input type="radio" name="activity" className="main-input sr-only" id="physical" defaultChecked={isExampleOutcome && exampleOutcome.type === "physical"}/>
                                                    <label htmlFor="physical">
                                                        <img src={physical} alt="physical" />
                                                    </label>
                                                </div>

                                                <div onMouseEnter={(e) => { setHoverType("Social") }} onMouseOut={(e) => { setHoverType(null) }} >
                                                    <input type="radio" name="activity" className="main-input sr-only" id="social" defaultChecked={isExampleOutcome && exampleOutcome.type === "social"}/>
                                                    <label htmlFor="social">
                                                        <img src={social} alt="social" />
                                                    </label>
                                                </div>


                                            </fieldset>

                                            <div className="next-container" data-indicator="3" >
                                                <div className="ok-button" onClick={handleClickIndicator}>OK ✓</div>
                                                <div className="press-enter-text">press <b>Enter</b> ↵</div>
                                            </div>


                                        </div>
                                    </div> : <></>}

                                {currentIndicator === 3 ?
                                    <div className="input-container" >
                                        <div className="prompt-container">
                                            <div className="prompt-number">3.</div>
                                            <div className="prompt-description">When do you want to complete your goal by?</div>
                                        </div>
                                        <div className="input-row-container">
                                            <input
                                                type="text"
                                                className="main-input yellow-background yellow-underline"
                                                name="end time"
                                                placeholder='Select an end time'
                                                required
                                                autoComplete="off"
                                                value={duration}
                                                onChange={e => reSetDuration(e)}
                                                onKeyPress={e => handleKeyPress(e)}
                                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}

                                            />

                                            <div className="next-container" data-indicator="1" >
                                                <div className="ok-button ok-button-create-goal" onClick={(e) => { handleSubmit(e) }}>Create Outcome</div>
                                                {/* <div className="press-enter-text">Create Gridgoal</div> */}
                                            </div>
                                        </div>
                                    </div> : <></>}

                            </div>




                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModalOutcome
