import React from 'react'

function Scorecard({value, name}) {
    return (
        <div className="scorecard-container">
            <div className="scorecard-value">{value }</div>
            <div className="scorecard-name">{name}</div>
        </div>
    )
}

export default Scorecard
