import React from 'react'
import { numberWithCommas } from '../utils/utils'
function GridgoalCell({ index, multiplier, num_cells, completedTotal, total, day }) {
    // console.log(index, multiplier, num_cells, completedTotal, total, index  + 1  < completedTotal / multiplier)
    let dayOfWeek = day % 7;
    let percentFilled;

    if(index  + 1  < completedTotal / multiplier){
        percentFilled=1;
    }
    else{
        // (completedTotal / multiplier) 
        percentFilled = .5;
    }
    // let colors = ["#E67571","#1ffab2","#5fcab2","#AEF5A2","#4dd2b5","#b1dab2","#9ffab2"]
    let activeClass = `active-grid-cell-${dayOfWeek}`;
    // console.log(`object`, activeClass, day)
    return (
        <div className="cell-container">
            {index < num_cells ?
                <>
                    <div className={`grid-goal-cell  ${index < completedTotal / multiplier ? activeClass : ""}`} 
                        style={{position: "relative",zIndex:"10"}}>
                        {/* <div className={`partial-fill ${index < completedTotal / multiplier ? activeClass : ""}`}   */}
                            {/* style={{width: `calc(var(--cell-width) * ${percentFilled})`}} > */}
                        {/* </div> */}
                        {numberWithCommas(Math.min(total, (index + 1) * multiplier))}

                    </div>
                </>
                : <></>
            }
        </div>

    )
}

export default GridgoalCell
