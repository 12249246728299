import React, { useState, useEffect } from 'react';
import ExampleElement from './ExampleElement';
import { numberWithCommas } from '../utils/utils';
// import { exampleGoals } from '../data/exampleGoals.js';
import '../styles/newGridGoalInputForm.css';
import dietary from '../assets/goalTypes/dietary.png';
import family from '../assets/goalTypes/family.png';
import financial from '../assets/goalTypes/financial.png';
import mental from '../assets/goalTypes/mental.png';
import physical from '../assets/goalTypes/physical.png';
import social from '../assets/goalTypes/social.png';
import { recordAction } from '../apiRequests/auth';
const actions = [
    { display: 'Run', value: "Run" },
    { display: 'Eat Less Than', value: "Eat Less Than" },
    { display: 'Walk', value: "Walk" },
    { display: 'Write', value: "Write" },
    { display: 'Speak', value: "Speak" },
    { display: 'Meet', value: "Meet" },
    { display: 'Do Yoga', value: "Do Yoga" },
    { display: 'Listen To', value: "Listen To" },
    { display: 'Watch upto', value: "Watch upto" },
    { display: 'Read', value: "Read" },
    { display: 'Give', value: "Give" },
    { display: 'Do', value: "Do" },
    { display: 'Stretch', value: "Stretch" },
    { display: 'Help', value: "Help" },
    { display: 'Donate', value: "Donate" },
    { display: 'Invest', value: "Invest" }]
const numbers = [
    { display: '500', value: '500' },
    { display: '100', value: '100' },
    { display: '52', value: '52' },
    { display: '75', value: '75' },
    { display: '150', value: '150' },
    { display: '64', value: '64' },
    { display: '365', value: '365' },
    { display: '200', value: '200' }]

const activities = [
    { display: 'Compliments', value: 'Compliments' },
    { display: 'Dollars', value: 'Dollars' },
    { display: 'Books', value: 'Books' },
    { display: 'Articles', value: 'Articles' },
    { display: 'Times', value: 'Times' },
    { display: 'People', value: 'People' },
    { display: 'Pictures', value: 'Pictures' },
    { display: 'Pushups', value: 'Pushups' },
    { display: 'Math Problems', value: 'Math Problems' },
    { display: 'Walls', value: 'Walls' },
    { display: 'Times', value: 'Times' },
    { display: 'Minutes', value: 'Minutes' },
    { display: 'Units', value: 'Units' },
    { display: 'Pull-ups', value: 'Pull-ups' },
    { display: 'Pounds', value: 'Pounds' },
    { display: 'Hours', value: 'Hours' },
    { display: 'Walks', value: 'Walks' },
    { display: 'Business Plans', value: 'Business Plans' },
    { display: 'Friends', value: 'Friends' },
    { display: 'Miles', value: 'Miles' },
    { display: 'Words', value: 'Words' },
];
const durations = [
    { display: '7 Day', value: '7' },
    { display: '1 Day', value: '1' },
    { display: '10 Days', value: '10' },
    { display: '2 Weeks', value: '14' },
    { display: '1 Month', value: '31' },
    { display: '30 Days', value: '30' },
    { display: '1 Week', value: '7' },
    { display: '90 Days', value: '90' },
    { display: '14 Days', value: '14' },
    { display: '20 Days', value: '20' },
]


function NewGridGoalInputForm({
    reSetVerb,
    reSetNumber,
    reSetNoun,
    reSetDuration,
    reSetType,

    reSetVerbExample,
    reSetNumberExample,
    reSetNounExample,
    reSetDurationExample,
    reSetTypeExample,

    verb,
    number,
    noun,
    duration,
    type,

    setVerb,
    setNumber,
    setNoun,
    setDuration,
    setType,

    submitNewGoalForm,
    setIncomingGoalFormData
}
) {

    const [currentIndicator, setCurrentIndicator] = useState(1);
    const [exampleArray, setExampleArray] = useState([]);
    const [hoverType, setHoverType] = useState('');
    const [clickType, setClickType] = useState('');
    const [perDayText, setPerDayText] = useState('');

    const handleKeyPress = (event) => {
        if (event.key === 9) {
            event.preventDefault()
        };

        if (event.key === 'Enter') {
            if (currentIndicator !== 6) {
                setCurrentIndicator(Number(currentIndicator) + 1);
                handleClickIndicator(event)
            }
        }
    }

    const handleSubmit = (e) => {
        if (verb !== "" && number !== "" && noun !== "" && duration !== "" && type !== "") {
            e.preventDefault();

            setIncomingGoalFormData(true);
            setCurrentIndicator(1);
            setVerb("");
            setNumber("");
            setNoun("");
            setDuration("");
            setType("");
            setClickType("");
            document.querySelectorAll('.sr-only').forEach(btn => { btn.checked = false });
            submitNewGoalForm([verb, number, noun, duration, type]);
            // e.target.reset()

            document.querySelectorAll(".modal--visible").forEach((el) => {
                el.classList.remove("modal--visible");
            });

            let resetEvent = {
                resetIndicator: 1
            };
            handleClickIndicator(resetEvent);

            // article.dataset.columns
            recordAction('Create Grid Goal')

        }
    }

    const handleClickIndicator = (e) => {
        // article.dataset.columns

        let indicator = e.target?.parentNode.dataset.indicator;

        if (indicator) {
            setCurrentIndicator(Number(indicator))
        }
        if (e.key) {
            indicator = currentIndicator + 1;
        }
        if (e.resetIndicator) {
            indicator = e.resetIndicator;
        }

        const clickedElement = document.querySelector(`.indicatior-container:nth-child(${indicator})`);
        const activeElements = document.querySelectorAll(".active-indicator");

        activeElements.forEach((activeElement) => {
            activeElement.classList.remove("active-indicator");
        })

        if (clickedElement && !clickedElement.classList.contains("active-indicator")) {
            clickedElement.classList.add("active-indicator")
        }

        const inputContainers = document.querySelectorAll('.input-container');
        // let elementHeight =  inputContainers[1].clientHeight;
        // const mainInputContainer = document.querySelector('.all-inputs-container');


        // let transformSum = 0;
        // inputContainers.forEach((inputContainer, i) => {

        //     let elementHeight = inputContainer.clientHeight;

        //     // if(i<Number(indicator)-1){
        //     //     transformSum+=elementHeight;
        //     // }
        //     // inputContainer.style.transform = `translateY(${-1 * (Number(indicator) - 1) * elementHeight}px`;
        //     if (inputContainer.classList.contains('active-input-container')) {
        //         inputContainer.classList.remove('active-input-container')
        //     };
        // })
        // console.log(`transformSum`, transformSum)
        // mainInputContainer.style.transform = `translateY(${-1 * transformSum}px`;


        // const inputContainerActive = document.querySelector(`.input-container:nth-child(${Number(indicator)})`);
        // if (inputContainerActive) {
        //     inputContainerActive.classList.add('active-input-container')
        // }

        const inputField = document.querySelector(`.input-container:nth-child(${Number(indicator)}) .main-input`)
        if (inputField) {
            inputField.focus({ preventScroll: true });
        }

    }
    useEffect(() => {
        if (currentIndicator === 1) {
            setExampleArray(actions)
        }
        if (currentIndicator === 2) {
            setExampleArray(numbers)
        }
        if (currentIndicator === 3) {
            setExampleArray(activities)
        }
        if (currentIndicator === 4) {
            setExampleArray(durations)
        }
        // if (currentIndicator === 5) {
        //     setExampleArray(durations)
        // }
    }, [currentIndicator])

    useEffect(() => {
        if (verb !== "" && number !== "" && noun !== "" && duration !== "") {

            let daysLeft = duration;

            let perDayValue = Math.max(1, (number) / daysLeft);

            perDayValue = perDayValue < 1 ? Number.parseFloat(perDayValue).toFixed(1) : Math.round(perDayValue);

            let daytext = `${verb} ${perDayValue} ${noun} per day`;

            setPerDayText(daytext)

        }
    }, [verb, number, noun, duration])

    return (
        <div className="new-grid-goal-input-form">

            <div className="indicator-row-container">
                <div data-indicator="1" className="indicatior-container active-indicator" >
                    <div className="indicator blue-background" onClick={handleClickIndicator}></div>
                    <div className="indicator-title" onClick={handleClickIndicator}>Action</div>
                </div>
                <div data-indicator="2" className="indicatior-container" >
                    <div className="indicator red-background" onClick={handleClickIndicator}></div>
                    <div className="indicator-title" onClick={handleClickIndicator}>Number</div>
                </div>
                <div data-indicator="3" className="indicatior-container" >
                    <div className="indicator green-background" onClick={handleClickIndicator}></div>
                    <div className="indicator-title" onClick={handleClickIndicator}>Activity</div>
                </div>
                <div data-indicator="4" className="indicatior-container" >
                    <div className="indicator yellow-background" onClick={handleClickIndicator}></div>
                    <div className="indicator-title" onClick={handleClickIndicator}>Timeframe</div>
                </div>
                <div data-indicator="5" className="indicatior-container" >
                    <div className="indicator orange-background" onClick={handleClickIndicator}></div>
                    <div className="indicator-title" onClick={handleClickIndicator}>Goal Type</div>
                </div>
            </div>

            <div className="all-inputs-container">

                {currentIndicator === 1 ?
                    <div className="input-container active-input-container" >
                        <div className="prompt-container">
                            <div className="prompt-number">1.</div>
                            <div className="prompt-description">Let’s start with the <b>ACTION</b> part of your Grid Goal</div>
                        </div>
                        <div className="input-row-container">
                            <input
                                type="text"
                                className="main-input blue-background blue-underline"
                                name="verb"
                                placeholder='Type the ACTION you want to perform here...'
                                required
                                autoComplete="off"
                                value={verb}
                                onChange={e => reSetVerb(e)}
                                onKeyPress={e => handleKeyPress(e)}
                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}

                            />
                            <div className="next-container" data-indicator="2" >
                                <div className="ok-button" onClick={handleClickIndicator}>OK ✓</div>
                                <div className="press-enter-text">press <b>Enter</b> ↵</div>
                            </div>
                        </div>
                    </div> : <></>}

                {currentIndicator === 2 ?
                    <div className="input-container" >
                        <div className="prompt-container">
                            <div className="prompt-number">2.</div>
                            <div className="prompt-description">Now let’s pick the total <b>NUMBER</b> of time syou want to do the small activity</div>
                        </div>
                        <div className="input-row-container">
                            <input
                                type="number"
                                className="main-input red-background red-underline"
                                name="number"
                                placeholder='Type the NUMBER of times you’ll do your activity here...'
                                required
                                value={number}
                                autoComplete="off"
                                onChange={e => reSetNumber(e)}
                                onKeyPress={e => handleKeyPress(e)}
                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}

                            />
                            <div className="next-container" data-indicator="3" >
                                <div className="ok-button" onClick={handleClickIndicator}>OK ✓</div>
                                <div className="press-enter-text">press <b>Enter</b> ↵</div>
                            </div>
                        </div>
                    </div> : <></>}


                {currentIndicator === 3 ?
                    <div className="input-container" >
                        <div className="prompt-container">
                            <div className="prompt-number">3.</div>
                            <div className="prompt-description">It’s time to pick the <b>MINI-TASK</b> you want to acomplish {number ? number : "a number of"} times</div>
                        </div>
                        <div className="input-row-container">
                            <input
                                type="text"
                                className="main-input green-background green-underline"
                                name="noun"
                                placeholder='Type the thing you want to acomplish here...'
                                required
                                autoComplete="off"
                                value={noun}
                                onChange={e => reSetNoun(e)}
                                onKeyPress={e => handleKeyPress(e)}
                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}

                            />
                            <div className="next-container" data-indicator="4" >
                                <div className="ok-button" onClick={handleClickIndicator}>OK ✓</div>
                                <div className="press-enter-text">press <b>Enter</b> ↵</div>
                            </div>
                        </div>
                    </div> : <></>}


                {currentIndicator === 4 ?
                    <div className="input-container" >
                        <div className="prompt-container">
                            <div className="prompt-number">4.</div>
                            <div className="prompt-description">Finally, pick the <b>TIMEFRAME</b> in days which you’ll accomplish your goal</div>
                        </div>
                        <div className="input-row-container">
                            <input
                                type="number"
                                className="main-input yellow-background yellow-underline"
                                name="duration"
                                placeholder='Type the time frame here...'
                                required
                                autoComplete="off"
                                max="365"
                                value={duration}
                                onChange={e => reSetDuration(e)}
                                onKeyPress={e => handleKeyPress(e)}
                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}
                            />
                            <div className="next-container" data-indicator="5" >
                                <div className="ok-button" onClick={handleClickIndicator}>OK ✓</div>
                                <div className="press-enter-text">press <b>Enter</b> ↵</div>
                            </div>
                        </div>
                    </div> : <></>}


                {currentIndicator === 5 ?
                    <div className="input-container" >
                        <div className="prompt-container">
                            <div className="prompt-number">5.</div>
                            <div className="prompt-description">What <b>TYPE</b> of goal is this: {hoverType ? hoverType.toUpperCase() : clickType.toUpperCase()}</div>
                        </div>
                        <div className="input-row-container">

                            <fieldset className="image-fieldset"
                                onChange={e => { reSetType(e.target.id); setClickType(e.target.id) }}
                                onKeyPress={e => handleKeyPress(e)}
                                onKeyDown={(e) => { if (e.key === "Tab") e.preventDefault(); }}
                            >
                                <div onMouseEnter={(e) => { setHoverType("Dietary") }} onMouseOut={(e) => { setHoverType(null) }} >
                                    <input type="radio" name="activity" className="main-input sr-only" id="dietary" />
                                    <label htmlFor="dietary">
                                        <img src={dietary} alt="dietary" />
                                    </label>
                                </div>

                                <div onMouseEnter={(e) => { setHoverType("Family") }} onMouseOut={(e) => { setHoverType(null) }} >
                                    <input type="radio" name="activity" className="main-input sr-only" id="family" />
                                    <label htmlFor="family">
                                        <img src={family} alt="family" />
                                    </label>
                                </div>

                                <div onMouseEnter={(e) => { setHoverType("Financial") }} onMouseOut={(e) => { setHoverType(null) }} >
                                    <input type="radio" name="activity" className="main-input sr-only" id="financial" />
                                    <label htmlFor="financial">
                                        <img src={financial} alt="financial" />
                                    </label>
                                </div>

                                <div onMouseEnter={(e) => { setHoverType("Mental") }} onMouseOut={(e) => { setHoverType(null) }} >
                                    <input type="radio" name="activity" className="main-input sr-only" id="mental" />
                                    <label htmlFor="mental">
                                        <img src={mental} alt="mental" />
                                    </label>
                                </div>

                                <div onMouseEnter={(e) => { setHoverType("Physical") }} onMouseOut={(e) => { setHoverType(null) }} >
                                    <input type="radio" name="activity" className="main-input sr-only" id="physical" />
                                    <label htmlFor="physical">
                                        <img src={physical} alt="physical" />
                                    </label>
                                </div>

                                <div onMouseEnter={(e) => { setHoverType("Social") }} onMouseOut={(e) => { setHoverType(null) }} >
                                    <input type="radio" name="activity" className="main-input sr-only" id="social" />
                                    <label htmlFor="social">
                                        <img src={social} alt="social" />
                                    </label>
                                </div>


                            </fieldset>

                            <div className="next-container" data-indicator="6" >
                                <div className="ok-button ok-button-create-goal" onClick={(e) => { handleSubmit(e) }}>Create Gridgoal</div>
                                {/* <div className="press-enter-text">Create Gridgoal</div> */}
                            </div>

                        </div>
                    </div>
                    : <></>}


            </div>

            <h1 className="preview-text">{verb !== "" ? verb : "_____"} {number !== "" ? numberWithCommas(number) : "_____"} {noun !== "" ? noun : "_____"} in {duration !== "" ? duration : "_____"} {duration === 1 ? "day" : "days"}</h1>
            <h4 className="preview-text-per-basis">{verb !== "" && number !== "" && noun !== "" && duration !== "" ? perDayText : ""}</h4>


            {![5, 6].includes(currentIndicator) ?
                <div className="example-elements-container">
                    <div className="example-title">Example {"Actions"}</div>
                    <div className="example-elements">
                        {

                            exampleArray.map(
                                (exampleElement, i) =>
                                    <div key={i}>
                                        {/* exampleGoals */}
                                        {currentIndicator === 1 ? <ExampleElement text={exampleElement} action={reSetVerbExample} color={"blue"} /> : <></>}
                                        {currentIndicator === 2 ? <ExampleElement text={exampleElement} action={reSetNumberExample} color={"red"} /> : <></>}
                                        {currentIndicator === 3 ? <ExampleElement text={exampleElement} action={reSetNounExample} color={"green"} /> : <></>}
                                        {currentIndicator === 4 ? <ExampleElement text={exampleElement} action={reSetDurationExample} color={"yellow"} /> : <></>}
                                    </div>
                            )

                        }

                    </div>
                </div> : <></>}


        </div>
    )
}

export default NewGridGoalInputForm
