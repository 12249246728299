import React, { useState, useEffect } from 'react';
import newGridgoal from '../assets/newGridGoal.png'
import { numberWithCommas,getTimeRemaining } from '../utils/utils';
import bin from '../assets/bin.png';


function ActiveGridGoal({ goalData, onclick, setDeleteGridgoalID }) {
    // let [isActive, setIsActive] = useState(props.isActive);
    // let [goalData, setgoalData] = useState(goalData);
    // let [isActive, setIsActive] = useState(false);

    const [timeRemainingPercent, setTimeRemainingPercent] = useState(0);
    const [daysRemaining, setDaysRemaining] = useState("");
    const [hoursRemaining, setHoursRemaining] = useState("");

    const [endDay, setEndDay] = useState(0);
    // const [endHour, setEndHour] = useState(0);
    const [endMonth, setEndMonth] = useState(0);
    const [remainingTime, setRemainingTime] = useState('0%');
    const [completedPercent, setCompletedPercent] = useState('0%');
    const [update, setUpdate] = useState(true);
    useEffect(() => {
        const timeData = getTimeRemaining(goalData)
        setDaysRemaining(timeData.daysRemaining);
        setHoursRemaining(timeData.hoursRemaining);
        setEndDay(timeData.endDay)
        setEndMonth(timeData.endMonth)
        setRemainingTime(timeData.remainingTime)

        setTimeRemainingPercent((1 - Math.max( getTimeRemaining(goalData).remainingTime, 0) / goalData.total_time) * 100 + "%")
        setCompletedPercent(Math.round(goalData.total_completed / goalData.value * 100) + "%")
        
        
    }, [goalData])

 
    return (
        <>
            {goalData.status ?
                <div className="active-grid-goal-container" onClick={() => onclick(goalData)}>
                    <div className="grid-goal-header">
                        <div className="grid-goal-icon">
                            <img src={require(`../assets/goalTypes/${goalData.icon}.png`).default} alt="grid" />
                        </div>
                        <div className="name-date-container">
                            <div className="grid-goal-toprow">
                                <div className="grid-goal-name">
                                    {goalData.title}
                                </div>

                                <div className="grid-goal-delete" onClick={() => setDeleteGridgoalID(goalData.id)}>
                                    <img src={bin} alt="delete grid goal" />
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="grid-goal-progress-bars-container">
                        <div className="progress-container">
                            <div className="grid-goal-due-date-container">
                                <div className="time-remaining-text">
                                    Remaining Time: {daysRemaining} days, {hoursRemaining} hrs
                            </div>
                                <div className="grid-goal-due-date">
                                    Due: {endMonth} {endDay}
                                </div>
                            </div>
                            {goalData.total_time>0?
                            <div className="time-remaining-bar" style={{ width: timeRemainingPercent}}></div>:
                            <></>
                            }
                        </div>
                        <div className="progress-container">
                            <div className="completed-text-container">
                                <div className="completed-out-of">
                                    Completed: {numberWithCommas(goalData.total_completed)} out of {numberWithCommas(goalData.value)}
                                </div>
                                <div className="completed-percent">
                                    {completedPercent}
                                </div>
                            </div>
                            <div className="completed-bar" style={{ width: completedPercent }}>
                            </div>
                        </div>

                    </div>


                </div>

                :
                <>
                    <div className="active-grid-goal-container empty" data-modal-event="new-grid-goal" >
                        <div className="add-new-gridgoal">
                            <img src={newGridgoal} alt="add goal" />
                        </div>
                    </div>
                    {/* <Modal submitNewGoalForm={submitNewGoalForm} dataModalEvent={"placeholder"} setIncomingGoalFormData={setIncomingGoalFormData} /> */}
                </>

            }
        </>
    )
}

export default ActiveGridGoal
