import React, { useState, useEffect } from 'react'
import Login from './Login';
// import axios from 'axios';
// import { saveToLocal } from "../utils/utils";

import { useLogin } from '../Context/LoginProvider';

import { useOutcomeData, useSelectedOutcomeData } from '../Context/OutcomeDataProvider';
import { useGoalData, useSelectedGoalData } from '../Context/GoalDataProvider';
import Burger from './Burger';
import NavLinks from './NavLinks';
import { Link } from "react-router-dom";


// import Axios from 'Ax'
function Navbar({ setRunModals }) {
    const [openLogin, setOpenLogin] = useState(false);

    const [loggedIn, setLoggedIn] = useLogin();
    const [firstTime, setFirstTime] = useState(false);
    const [outcomesExists, setOutcomeExists] = useState(false)


    const [goalDatas, setGoalDatas] = useGoalData();
    const [selectedGoal, setSelectedGoal] = useSelectedGoalData();
    const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();
    const [outcomeDatas, setOutcomeDatas] = useOutcomeData();

    const [returnE, setReturnE] = useState({});

    // Modal states
    const [forgotPassword, setForgotPassword] = useState(false);
    const [mainLogin, setMainLogin] = useState(true);
    const [registerUser, setRegisterUser] = useState(false);


    // const [open, setOpen] = useState(false);

    const submitForm = (e) => {
        setOpenLogin(!openLogin);
        setReturnE(e)
    }

    const createNewAccount = (e) => {
        setOpenLogin(!openLogin);
        setGoalDatas([]);
        setOutcomeDatas([]);
        setRunModals(true);
    }


    useEffect(() => {
        var username = localStorage.getItem("username");
        var user_id = localStorage.getItem("user_id");
        if (!username || !user_id) {
            // console.log("Not Logged In")
            // setOpenLogin(!openLogin);
            setFirstTime(true)
        }
    }, []);


    useEffect(() => {
        if (outcomeDatas && outcomeDatas.length > 0) {
            setOutcomeExists(true)
        }
        else {
            setOutcomeExists(false)
        }
    }, [returnE, outcomeDatas]);

    function disableClick(event) {
        event.preventDefault();
        let tooltip = document.querySelector(".gridgoal-link-tooltip");
        tooltip.style.display = "flex";
        setTimeout(() => {
            tooltip.style.display = "none";
        }, 4000);

    }

    function comingSoonAnalytics(event) {
        event.preventDefault();
        let tooltip = document.querySelector(".gridgoal-link-tooltip-analytics");
        tooltip.style.display = "flex";

        setTimeout(() => {
            tooltip.style.display = "none";
        }, 4000);
    }

    function comingSoonAccountability(event) {
        event.preventDefault();
        let tooltip = document.querySelector(".gridgoal-link-tooltip-accountability");
        tooltip.style.display = "flex";

        setTimeout(() => {
            tooltip.style.display = "none";
        }, 4000);
    }


    return (
        <div className="navbar">

            <Link to="/outcomes" >   <div className="logo">Grid Goal</div></Link>

            <div className="mobile-navbar">
                <Burger disableClick={disableClick} comingSoonAnalytics={comingSoonAnalytics} comingSoonAccountability={comingSoonAccountability} outcomesExists={outcomesExists} modalStates={{
                    forgotPassword, setForgotPassword,
                    mainLogin, setMainLogin,
                    registerUser, setRegisterUser,
                    setOpenLogin, openLogin
                }} />

                {loggedIn ?
                    <div className="login-icon" onClick={() => { setOpenLogin(!openLogin); }}></div> :
                    <></>
                }
            </div>


            <div className="desktop-navbar">
                <NavLinks disableClick={disableClick} comingSoonAnalytics={comingSoonAnalytics} comingSoonAccountability={comingSoonAccountability} outcomesExists={outcomesExists} />
                
                {/* {loggedIn ?
                    <div className="login-icon" onClick={() => { setOpenLogin(!openLogin); }}></div> :
                    <div className="login-icons" >
                        <div className="sign-in nav-action desk" onClick={() => { setMainLogin(true); setRegisterUser(false); setOpenLogin(!openLogin); }}>Sign in</div>
                        <div className="sign-up nav-action desk" onClick={() => { setRegisterUser(true); setMainLogin(false); setOpenLogin(!openLogin); }}>Create Account</div>
                    </div>
                } */}
            </div>


            {openLogin ?
                <Login setOpenLogin={setOpenLogin} submitForm={submitForm} createNewAccount={createNewAccount} firstTime={firstTime} setFirstTime={setFirstTime} modalStates={{
                    forgotPassword, setForgotPassword,
                    mainLogin, setMainLogin,
                    registerUser, setRegisterUser,
                    setOpenLogin, openLogin
                }
                }
                /> :
                <></>
            }


        </div>
    )
}

export default Navbar
