import React, { useState, useEffect } from 'react'
import ModalGridgoal from '../Components/ModalGridgoal';
import ActiveGridGoal from "../Components/ActiveGridGoal";
import Gridgoal from "../Components/Gridgoal";
import BarChart from '../Components/Analytics/BarChart';
import LineChart from '../Components/Analytics/LineChart';

// import goalsExample from "./data/goalsExample.json";
import defaultActivity from "../data/defaultActivity.json";
import { saveToLocal, getFromLocal, numberWithCommas, getTimeRemaining } from "../utils/utils";
import axios from 'axios';
import { useGoalData, useSelectedGoalData } from '../Context/GoalDataProvider'
import { useLogin } from '../Context/LoginProvider';
import { recordAction } from '../apiRequests/auth';
import '../styles/Analytics.css'
import Scorecard from '../Components/Analytics/Scorecard';
import ALL_GLOBAL_VARIABLES from "../global";

import { useOutcomeData, useSelectedOutcomeData } from '../Context/OutcomeDataProvider';

function Analytics({ setRunModals, incomingGoalsDBData, setIncomingGoalsDBData, goalsDBData, setGoalsDBData }) {
    const [goalDatas, setGoalDatas] = useGoalData();
    const [loggedIn, setLoggedIn] = useLogin();
    const [newGoalForm, submitNewGoalForm] = useState('');
    const [deleteGridgoalID, setDeleteGridgoalID] = useState('');
    const [incomingGoalFormData, setIncomingGoalFormData] = useState(false);
    const [refreshCharts, setRefreshCharts] = useState(false);

    const [selectedGoal, setSelectedGoal] = useSelectedGoalData();
    const [selectedOutcome, setSelectedOutcome] = useSelectedOutcomeData();
    const [outcomeDatas, setOutcomeDatas] = useOutcomeData();

    // const [formFill, setFormFill] = useState('');

    const [daysRemaining, setDaysRemaining] = useState("");
    const [hoursRemaining, setHoursRemaining] = useState("");

    const [endDay, setEndDay] = useState(0);
    // const [endHour, setEndHour] = useState(0);
    const [endMonth, setEndMonth] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0);

    const [lineAverage, setLineAverage] = useState(0);


    useEffect(() => {
        const timeData = getTimeRemaining(selectedGoal)
        setDaysRemaining(timeData.daysRemaining);
        setHoursRemaining(timeData.hoursRemaining);
        setEndDay(timeData.endDay)
        setEndMonth(timeData.endMonth)
        setRemainingTime(timeData.remainingTime);

        let now = new Date();
        let endDate = new Date(selectedGoal.due_date);
        let daysLeft = (endDate.getTime() - now.getTime()) / 1000 / 86400;
        let perDayValue = Math.max(1, (selectedGoal.value - selectedGoal.total_completed) / daysLeft);
        perDayValue = perDayValue < 1 ? Number.parseFloat(perDayValue).toFixed(1) : Math.round(perDayValue);
        setLineAverage(perDayValue)

    }, [selectedGoal])


    function saveUpdatedGoals(data) {
        const user_id = getFromLocal("user_id", -1)

        if (user_id !== -1) {
            axios.post(`${ALL_GLOBAL_VARIABLES.host}/update_goals_by_outcome_id`,
                {
                    "user_id": user_id,
                    "grid_data": data
                }
            ).then((response) => {
            })
        }
    }

    function removeGoalId(id_to_remove) {
        const user_id = getFromLocal("user_id", -1)
        if (user_id !== -1) {
            axios.post(`${ALL_GLOBAL_VARIABLES.host}/remove-goal-id`,
                {
                    "user_id": user_id,
                    "id_to_remove": id_to_remove
                }
            ).then((response) => {

            })
        }
    }



    function loadUserData(user_id, username, outcome_id) {
        axios.post(`${ALL_GLOBAL_VARIABLES.host}/all-goals-by-outcome-id`,
            {
                "username": username,
                "user_id": user_id,
                "gg_set": outcome_id
            }
        ).then((response) => {
            setIncomingGoalsDBData(true);
            setGoalsDBData(response.data)
            return response.data
        })
    }

    function handleClick(param) {
        setSelectedGoal({ ...param })
        // param is the argument you passed to the function
        // e is the event object that returned
    };

    const [filteredGoals, setFilteredGoals] = useState(() => {
        let gs = [...goalDatas];
        for (let i = gs.length; i < 4; i++) {
            gs.push(defaultActivity)
        }
        return gs;
    });


    useEffect(() => {
        setRunModals(true)
    }, [])



    useEffect(() => {
        if (goalsDBData !== "" && incomingGoalsDBData) {

            let goals = [...goalsDBData];
            saveToLocal("grid-goal-activity-data", goals);

            setGoalDatas(goals);

            let gs = [...goals];

            for (let i = gs.length; i < 4; i++) { // Change the number of open grid goals to 6
                gs.push(defaultActivity)
            }

            setSelectedGoal(gs[0]);//This is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
            setFilteredGoals(gs);
            setIncomingGoalsDBData(false);

        }
    }, [incomingGoalsDBData, goalsDBData])

    useEffect(() => {
        setFilteredGoals(filteredGoals);
        setRefreshCharts(false);
    }, [refreshCharts])

    useEffect(() => {
        if (deleteGridgoalID !== "" && deleteGridgoalID) {

            let goals = [...goalDatas];
            let removedArray = goals.filter(el => el.id !== deleteGridgoalID);
            saveToLocal("grid-goal-activity-data", removedArray);
            setGoalDatas(removedArray);
            saveUpdatedGoals(removedArray)
            removeGoalId(deleteGridgoalID);

            let gs = [...removedArray];
            for (let i = gs.length; i < 4; i++) {
                gs.push(defaultActivity)
            }
            if (gs.filter(el => el.id !== -1).length === 0) {
                setSelectedGoal()
            }
            setFilteredGoals(gs)
            setDeleteGridgoalID("");
            setRunModals(true);


            recordAction('Delete Gridgoal')

        }
    }, [deleteGridgoalID, goalDatas])


    useEffect(() => {
        if (newGoalForm !== "" && incomingGoalFormData) {
            const verb = newGoalForm[0];
            const number = Number(newGoalForm[1]);
            const noun = newGoalForm[2];
            const duration = Number(newGoalForm[3]);
            const type = newGoalForm[4];


            const multiplier = number >= 200 ? Math.ceil(number / 200) : 1;

            let goals = [...goalDatas];

            let today = new Date();
            let endDate = new Date(today.setDate(today.getDate() + duration)).toString();

            // let emptyProgress = { ...[...Array(duration).keys()].map((elem) => (elem)) }
            // Object.keys(emptyProgress).forEach(v => emptyProgress[v] = 0)
            let emptyProgress = {};
            let newGoal = {
                id: Math.random().toString(36).substr(2, 9), // Generates a random 8 digit id
                due_date: endDate,
                icon: type,
                multiplier: multiplier,
                num_cells: Math.ceil(Number(number) / multiplier),
                status: 1,
                title: `${verb} ${numberWithCommas(number)} ${noun} in ${duration} days`,
                total_completed: 0,
                total_time: duration,
                value: number,
                progress: emptyProgress,
                verb: verb,
                number: number,
                noun: noun,
                duration: duration
                // progress:{...[...Array(duration).keys()].map((elem) => (elem,0))}
            }

            addCreateGoal(newGoal)
            goals.push(newGoal)
            saveUpdatedGoals(goals)
            // Get new goal id
            saveToLocal("grid-goal-activity-data", goals);
            setGoalDatas(goals);

            let gs = [...goals];

            for (let i = gs.length; i < 4; i++) {
                gs.push(defaultActivity)
            }

            setSelectedGoal(newGoal)//THis is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
            setFilteredGoals(gs)
            setIncomingGoalFormData(false)

        }
    }, [newGoalForm, goalDatas, incomingGoalFormData])




    useEffect(() => {
        if (loggedIn === false) {

            let goals = [...goalDatas];
            let gs = [...goals];

            for (let i = gs.length; i < 4; i++) {
                gs.push(defaultActivity)
            }

            // setSelectedGoal(newGoal)//THis is hilarious. I thought about fixing this earlier but didn't. It ended up causing a bug that took 20 minutes to fix
            setFilteredGoals(gs)
            setIncomingGoalFormData(false)
        }
    }, [loggedIn])


    useEffect(() => {
        const user_id = getFromLocal("user_id", -1)
        const username = getFromLocal("username", "");
        // if (user_id !== -1 && username !== "") {
        //     loadUserData(user_id, username, selectedOutcome.active_gridgoal_ids);
        // }
    }, [])


    const addCreateGoal = (new_goal) => {
        const user_id = getFromLocal("user_id", -1)

        axios.post(`${ALL_GLOBAL_VARIABLES.host}/create-new-goal`,
            {
                "user_id": user_id,
                "new_goal": new_goal
            }).then((response) => {

            });
    }

    return (
        <>

            <div className="page-content">
                <div className="active-grid-goals-container">
                    {
                        filteredGoals.map((goalData, i) =>

                            <ActiveGridGoal key={i} onclick={handleClick} goalData={goalData} submitNewGoalForm={submitNewGoalForm} setIncomingGoalFormData={setIncomingGoalFormData} setDeleteGridgoalID={setDeleteGridgoalID} />
                        )
                    }

                </div>
                    <div className="analytics-content">
                {/* <AnalyticLayout /> */}
                <div className="graph-containers">
                    <BarChart width={350} height={200} dataIn={selectedGoal.progress} loading={true} error={"No data to show"} lineAverage={lineAverage} totalDays={selectedGoal.total_time} />
                    <LineChart width={350} height={200} dataIn={selectedGoal.progress} loading={true} error={"No data to show"} totalDays={selectedGoal.total_time} maxHeight={selectedGoal.value} goal = {selectedGoal}/>
                </div>

                <div className="scorecards-container">
                    <Scorecard value={"56%"} name ="% Completed"/>
                    <Scorecard value={"56%"} name ="Best Day"/>
                    <Scorecard value={"56%"} name ="Current Gridgoals"/>
                    <Scorecard value={"56%"} name ="Productive Days"/>
                    <Scorecard value={"56%"} name ="Productive Rate"/>
                    <Scorecard value={"56%"} name ="Past Gridgoals"/>
                    <Scorecard value={"56%"} name ="Rest Days"/>
                    <Scorecard value={"56%"} name ="Elapsed Days"/>
                    <Scorecard value={"56%"} name ="Average Day"/>
                    <Scorecard value={"56%"} name ="Rate to Finish"/>
                    <Scorecard value={"56%"} name ="Remaining Days"/>



                </div>
                </div>

                {/* <Gridgoal selectedGoal={selectedGoal} setRefreshCharts={setRefreshCharts} /> */}
            </div>
            {/* <ModalGridgoal submitNewGoalForm={submitNewGoalForm} dataModalEvent={"new-grid-goal"} setIncomingGoalFormData={setIncomingGoalFormData} /> */}
        </>
    )
}

export default Analytics
