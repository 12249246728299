import React, { useState } from 'react'
import { randomString } from "../utils/utils";
function TemplateContainer({ setExampleOutcome, exampleData, modalType }) {

    const [rotatedArrow, setRotatedArrow] = useState(true);


    const enableModal = (e, example) => {
        document.querySelectorAll(`.js-modal--${modalType}`).forEach((el) => {
            el.classList.add("modal--visible");
        });
        // Hide the body's scroll bar, so only the modal's scroll is shown. Also helps to ensure mobile scrolling is on correct element
        document.querySelector("body").classList.add("no-scroll--modal");
        setExampleOutcome(example)
    }

    const toggleTemplate = (e) => {
        let arrow = document.querySelector('.outcome-arrow');
        let exampleOutcomesAll = document.querySelector('.outcome-container-wrapper-examples');
        let angle = rotatedArrow ? -1 * 270 : 270
        setRotatedArrow(!rotatedArrow)
        arrow.style.transform = `rotateZ(${angle}deg)`;
        exampleOutcomesAll.classList.toggle = 'collapsed-examples';
    }


    return (
        <div className={`outcome-container-wrapper-examples ${rotatedArrow ? 'collapsed-examples' : ""}`}>

            <div className="outcome-arrow" onClick={toggleTemplate}>
                <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.2876 0.5H3.05491C0.339458 0.5 -0.978857 3.81993 0.99688 5.68277L10.9707 15.0867C12.1146 16.1652 13.897 16.1777 15.0559 15.1154L25.3148 5.71146C27.3316 3.86266 26.0236 0.5 23.2876 0.5Z" />
                </svg>
            </div>

            <div className="example-outcomes-title">
                Use a Template
            </div>
            <div className="examples-outcomes">

                {exampleData.map((example, i) =>
                    // onClick={()=>{applyExampleOutcome(example)}}
                    <div key={randomString()} className="example-outcome" onClick={(e) => { enableModal(e, example); toggleTemplate(e);}}>
                        <div className="left-column-example">
                            <img className="example-outcome-image" src={require(`../assets/goalTypes/${example.type}.png`).default} alt="grid" />
                            <div className="example-outcome-title"> {example.title}</div>
                        </div>

                        <div className="example-outcome-duration"> {Math.floor(example.duration / 30)} Months </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TemplateContainer
