
let outs = [
    "Learn to Cook",
    "Get into real estate",
    "Become fit and healthy",
    "Learn a Language",
    "Get Finances in Order",
    "Get out of Debt",
    "Buy a home",
    "Get more organized",
    "Connect with my family",
    "Buy a new car",
    "Disconnect from technology",
    "Remodel my home",
    "Quit my vice",
    "Learn to code",
    "Reconnect with friends",
    "Find a partner",
    "Become an expert in your field",
    "Save 10,000 Dollars",
    "Make a living off passive income",
    "Gain Subscribers on YouTube",
    ""
]
export const exampleOutcomes = [
    {
    title: "Lose 30 Pounds",
    duration: 180,
    type: "physical",
    gridGoals: [
        "Run 200 Miles 90",
        "Eat Less than 200000 Calories 90",
        "Eat less than 4000 grams of sugar 90",
        "Do 10000 Pushups 90",
        "Get 700 hours of sleep 90",
        "Drink 9000 ounces of water 90",
        "Fast for 1400 hours 90",]
},

{
    title: "Run a Marathon",
    duration: 180,
    type: "physical",
    gridGoals: [
        "Run 500 Miles 120",
        "Stretch 1000 minutes 120",
        "Do 10000 situps 90",
        "Do 3000 pushups 90",
        "Drink 9000 ounces of water 90",
        "Do Breath work for 300 minutes 120",
        "Squat 10000 times 120",
        "Hold a Plank for 12000 seconds 90",]
},

{
    title: "Write a book",
    duration: 180,
    type: "mental",
    gridGoals: [
        "Write 120000 words 120",
        "Read 3000 pages 100",
        "Interview 100 domain experts 90",
        "Read 300 articles 120",
        "Edit for 4000 minutes 60",
        "Outline 1000 minutes 30",
        "Tell 300 people about the book 120",]
},

{
    title: "Start a business",
    duration: 180,
    type: "social",
    gridGoals: [
        "Talk to 1000 people 60",
        "Code for 5000 mintues 60",
        "Research for 1000 minutes 60",
        "Network for 2000 minutes 60",
        "Read 1000 pages 60",]
},

{
    title: "Gain followers on Twitter",
    duration: 180,
    type: "social",
    gridGoals: [
        "Tweet 2000 times 90",
        "Retweet 4000 times 90",
        "Comment  7000 times 90",
        "Tweet 500 threads 90",
        "Direct message 500 people 90",]
},

{
    title: "Get a job",
    duration: 180,
    type: "financial",
    gridGoals: [
        "Research 300 potential empoyers 60",
        "Work on cover letters for 2000 minutes 60",
        "Network with people for  5000 minutes 60",
        "Talk to 1500 people 60",
        "Practice interview techniques for 1000 minutes 60",]
},

{
    title: "Waste less time",
    duration: 180,
    type: "mental",
    gridGoals: [
        "Spends less than 5000 minutes on youtube 120",
        "Watch less than  5000 minutes of netflix 120",
        "Spend less than 3000 minutes on twitter 120",
        "Spend less than 4000 minutes on facebook 120",
        "Sleep for 1000 hours 120",]
}
];